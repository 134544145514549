import React from 'react'
import { BeatLoader } from 'react-spinners'
import ContentLoader from 'react-content-loader'

export default function Loading() {
  return (
    <section className="xs:p-2 m-auto relative flex flex-col items-center">
      <div className="lg:w-480px md:w-3/4 xs:w-full mb-5 flex items-center justify-between">
        <ContentLoader
          speed={2}
          width={480}
          height={50}
          viewBox="0 0 480 50"
          backgroundColor="#f3f3f3"
          foregroundColor="#e6e6e6"
        >
          <rect x="0" y="0" rx="0" ry="0" width="480" height="50" />
        </ContentLoader>
      </div>
      <div className="lg:w-480px md:w-3/4 xs:w-full mb-5 flex items-center justify-between">
        <div className="bg-secondary rounded-2xl p-6 w-1/4 rounded-2xl lg:w-480px md:w-full xs:w-full shadow-4xl md:rounded-lg xs:rounded-lg">
          <ContentLoader
            speed={2}
            width={400}
            height={460}
            viewBox="0 0 400 460"
            backgroundColor="#f3f3f3"
            foregroundColor="#e6e6e6"
          >
            <circle cx="28" cy="29" r="26" />
            <rect x="65" y="10" rx="2" ry="2" width="323" height="15" />
            <rect x="65" y="31" rx="2" ry="2" width="323" height="15" />
            <circle cx="27" cy="89" r="26" />
            <rect x="65" y="70" rx="2" ry="2" width="323" height="15" />
            <rect x="65" y="91" rx="2" ry="2" width="323" height="15" />
            <circle cx="28" cy="148" r="26" />
            <rect x="65" y="129" rx="2" ry="2" width="323" height="15" />
            <rect x="65" y="150" rx="2" ry="2" width="323" height="15" />
            <circle cx="29" cy="208" r="26" />
            <rect x="65" y="189" rx="2" ry="2" width="323" height="15" />
            <rect x="65" y="210" rx="2" ry="2" width="323" height="15" />
            <rect x="15" y="273" rx="0" ry="0" width="376" height="23" />
            <rect x="15" y="247" rx="0" ry="0" width="376" height="5" />
            <rect x="15" y="308" rx="0" ry="0" width="376" height="23" />
            <rect x="15" y="343" rx="0" ry="0" width="376" height="23" />
            <rect x="15" y="378" rx="0" ry="0" width="376" height="23" />
          </ContentLoader>
        </div>
      </div>
    </section>
  )
}

export const BeatLoading = () => {
  return <BeatLoader size={5} color="#ffffff" />
}
