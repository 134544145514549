import React, { useEffect, useRef, useState } from 'react'
import MicroModal from 'react-micro-modal'
import { TokenMetadata } from '~services/ft-contract'
import { ArrowDownWhite } from '../icon'
import { isMobile } from '~utils/device'
import { FormattedMessage, useIntl } from 'react-intl'
import { TokenBalancesView } from '~services/token'
import { IoCloseOutline } from 'react-icons/io5'
// import CommonBasses from '../../components/tokens/CommonBasses'
import Table from '../../components/table/Table'
import { useTokensData } from '~state/token'
import { toRealSymbol } from '~utils/token'
import { toPrecision, divide } from '~utils/numbers'
import { STABLE_TOKEN_USN_IDS } from '~services/near'
import { STABLE_TOKEN_IDS } from '~services/near'

function sort(a: any, b: any) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b)
  } else if (typeof a === 'number' && typeof b === 'number') {
    return a - b
  } else {
    return a
  }
}
export function tokenPrice(price: string, error?: boolean) {
  return <span className="text-xs text-primaryText">{`$${error || !price ? '-' : toPrecision(price, 2)}`}</span>
}

export function SingleToken({ token, price }: { token: TokenMetadata; price: string }) {
  return (
    <>
      {token.icon ? (
        <img
          src={token.icon}
          alt={toRealSymbol(token.symbol)}
          style={{
            width: '25px',
            height: '25px',
          }}
          className="inline-block mr-2 border rounded-full"
        />
      ) : (
        <div
          className="inline-block mr-2 border rounded-full border-primary"
          style={{
            width: '25px',
            height: '25px',
          }}
        ></div>
      )}
      <span className="text-primaryText">
        <div
          style={{
            position: 'relative',
            top: `${price ? '2px' : ''}`,
          }}
        >
          {toRealSymbol(token.symbol)}
        </div>
        <span
          style={{
            position: 'relative',
            bottom: `${price ? '2px' : ''}`,
          }}
        >
          {price ? tokenPrice(price) : null}
        </span>
      </span>
    </>
  )
}

export const StableSelectToken = ({
  onSelect,
  tokens,
  selected,
}: {
  tokens: TokenMetadata[]
  onSelect?: (token: TokenMetadata) => void
  selected: string | React.ReactElement
}) => {
  const stableTokensIdList = new Array(...new Set(STABLE_TOKEN_USN_IDS.concat(STABLE_TOKEN_IDS)))

  const ref = useRef(null)

  const [visible, setVisible] = useState(false)

  const stableTokens = stableTokensIdList.map((id) => tokens.find((token) => token.id === id))
  // const stableTokens = stableTokensIdList

  useEffect(() => {
    if (visible)
      document.addEventListener('click', () => {
        setVisible(false)
      })
  }, [visible])

  return (
    <div className="lg:w-4/12 md:w-3/12 xs:w-4/12 outline-none my-auto relative overflow-visible">
      <div
        className={`w-full relative `}
        onClick={(e) => {
          e.nativeEvent.stopImmediatePropagation()
          if (!visible && document.getElementsByClassName('stable-token-selector')?.[0]) {
            ref.current = document.getElementsByClassName('stable-token-selector')?.[0]
            ref.current.click()
          }
          setVisible(!visible)
        }}
      >
        {selected}
      </div>
      <div
        className={`stable-token-selector rounded-lg flex flex-col top-12 p-1.5 shadow-4xl ${
          visible ? 'block' : 'hidden'
        } absolute`}
        style={{
          background: 'rgba(230, 230, 230, 0.8)',
          backdropFilter: 'blur(4px)',
          WebkitBackdropFilter: 'blur(4px)',
          width: '150px',
          zIndex: 999,
          left: 0,
        }}
      >
        {stableTokens.map((token) => {
          return (
            <div
              className={`${'hover:bg-black hover:bg-opacity-10'} cursor-pointer rounded-lg flex items-center text-primaryText py-2 pl-4 pr-2`}
              key={token.id}
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation()

                setVisible(!visible)
                onSelect(token)
              }}
            >
              <span>
                {token.icon ? (
                  <img
                    className="rounded-full border mr-3 border-white"
                    src={token.icon}
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                ) : (
                  <div
                    className="rounded-full border mr-3 border-white"
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                )}
              </span>
              <span className="font-semibold text-sm">{toRealSymbol(token.symbol)}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default function SelectToken({
  tokens,
  selected,
  render,
  onSelect,
  standalone,
  placeholder,
  balances,
  tokenPriceList,
  forCross,
}: {
  tokens: TokenMetadata[]
  selected: string | React.ReactElement
  standalone?: boolean
  placeholder?: string
  render?: (token: TokenMetadata) => string
  onSelect?: (token: TokenMetadata) => void
  balances?: TokenBalancesView
  tokenPriceList?: Record<string, any>
  forCross?: boolean
}) {
  const [visible, setVisible] = useState(false)
  const [listData, setListData] = useState<TokenMetadata[]>([])
  const [currentSort, setSort] = useState<string>('down')
  const [sortBy, setSortBy] = useState<string>('near')
  // const [showCommonBasses, setShowCommonBasses] = useState<boolean>(true)

  if (!onSelect) {
    return (
      <button className="focus:outline-none p-1" type="button">
        {selected}
      </button>
    )
  }
  const dialogWidth = isMobile() ? '75%' : forCross ? '25%' : '20%'
  const dialogMinwidth = isMobile() ? 340 : 380
  const dialogHidth = isMobile() ? '95%' : '45%'
  const intl = useIntl()
  const { tokensData, loading: loadingTokensData, trigger } = useTokensData(tokens, balances)
  useEffect(() => {
    trigger()
  }, [trigger])

  useEffect(() => {
    if (!loadingTokensData) {
      const sortedData = [...tokensData].sort(sortTypes[currentSort].fn)
      setListData(sortedData)
    }
  }, [loadingTokensData, tokensData])

  useEffect(() => {
    if (!!tokensData) {
      const sortedData = [...tokensData].sort(sortTypes[currentSort].fn)
      setListData(sortedData)
    }
  }, [currentSort, sortBy])

  const sortTypes: { [key: string]: any } = {
    up: {
      class: 'sort-up',
      fn: (a: any, b: any) => sort(a[sortBy], b[sortBy]),
    },
    down: {
      class: 'sort-down',
      fn: (a: any, b: any) => sort(b[sortBy], a[sortBy]),
    },
    default: {
      class: 'sort',
      fn: (a: any, b: any) => a,
    },
  }

  const onSortChange = (params: string) => {
    if (params === sortBy) {
      let nextSort
      if (currentSort === 'down') nextSort = 'up'
      else if (currentSort === 'up') nextSort = 'down'
      setSort(nextSort)
    } else {
      setSort('up')
    }
    setSortBy(params)
  }

  const handleClose = () => {
    const sortedData = [...tokensData].sort(sortTypes[currentSort].fn)
    setListData(sortedData)
    setVisible(false)
    // setShowCommonBasses(true)
  }

  return (
    <MicroModal
      open={visible}
      handleClose={handleClose}
      trigger={() => (
        <div
          className={`focus:outline-none my-auto  ${standalone ? 'w-full' : 'w-3/12'}`}
          onClick={() => setVisible(true)}
        >
          {selected || (
            <section
              className={`flex justify-between items-center px-3 py-2 ${
                standalone
                  ? 'bg-white text-primaryText relative flex overflow-hidden rounded align-center my-2 shadow-4xl'
                  : ''
              }`}
            >
              <p className="text-lg text-gray-400 leading-none" style={{ lineHeight: 'unset' }}>
                {placeholder ?? 'Select'}
              </p>
              <div className="pl-2">
                <ArrowDownWhite />
              </div>
            </section>
          )}
        </div>
      )}
      overrides={{
        Overlay: {
          style: {
            zIndex: 110,
            backgroundColor: 'rgba(0, 19, 32, 0.65)',
            backdropFilter: 'blur(4px)',
            WebkitBackdropFilter: 'blur(4px)',
          },
        },
        Dialog: {
          style: {
            width: dialogWidth,
            minWidth: dialogMinwidth,
            borderRadius: '0.75rem',
            padding: '1.5rem 0',
            background: '#FFF',
            height: dialogHidth,
            zIndex: 100,
          },
        },
      }}
    >
      {() => (
        <section className="text-primaryText">
          <div className="flex items-center justify-between pb-5 px-8 relative">
            <h2 className="text-sm text-center">
              <FormattedMessage id="select_token" defaultMessage="Select Token" />
            </h2>
            <IoCloseOutline
              onClick={() => handleClose()}
              className="absolute text-gray-400 text-2xl right-6 cursor-pointer"
            />
          </div>
          <Table
            sortBy={sortBy}
            tokenPriceList={tokenPriceList}
            currentSort={currentSort}
            onSortChange={onSortChange}
            tokens={listData}
            onClick={(token) => {
              onSelect && onSelect(token)
              handleClose()
            }}
            balances={balances}
            forCross={forCross}
          />
        </section>
      )}
    </MicroModal>
  )
}
