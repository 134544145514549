import React, { useState } from 'react'
import { TokenMetadata } from '~services/ft-contract'
import { toPrecision } from '~utils/numbers'
import { SingleToken } from '../forms/SelectToken'

interface TokenProps {
  token: TokenMetadata
  onClick: (token: TokenMetadata) => void
  totalAmount?: string
  sortBy?: string
  price?: string
  index?: number
}

export default function Token({ token, onClick, sortBy, price, index }: TokenProps) {
  const { icon, symbol, id, near, total } = token

  const displayBalance = 0 < Number(near) && Number(near) < 0.001 ? '< 0.001' : toPrecision(String(near), 3)

  const [hover, setHover] = useState(false)

  return (
    <div
      key={id}
      className="hover:bg-black hover:bg-opacity-10 flex items-center justify-between w-full relative"
      onClick={() => onClick && onClick(token)}
      style={{
        height: '56px',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`xs:text-xs text-sm pl-8 ${
          index === 0 ? (!price ? 'pt-6 pb-4' : 'pt-4 pb-2') : !price ? 'py-4' : 'py-2'
        }  cursor-pointer flex w-1/2 items-center`}
      >
        <SingleToken token={token} price={price} />
      </div>
      <div
        className={`${index === 0 ? 'pt-6 pb-4' : 'py-4'} xs:text-xs text-sm w-1/2 text-right ${
          sortBy === 'near' ? 'text-primaryText' : ''
        }`}
      >
        <div className="relative flex items-center justify-end pr-12">{displayBalance}</div>
      </div>
    </div>
  )
}
