import React from 'react'

export default function TokenIcon(props: { icon?: string; className?: string; style?: any }) {
  const { icon, className, style } = props
  return icon ? (
    <img
      className={`block ${className} rounded-full`}
      src={icon}
      style={style}
      alt=""
    />
  ) : (
    <div className={`rounded-full ${className} border border-primary border-solid`} style={style} />
  )
}
