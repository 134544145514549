export function getExtendConfig(env: string = process.env.NEAR_ENV) {
  switch (env) {
    case 'production':
    case 'mainnet':
      return {
        RPC_LIST: {
          defaultRpc: {
            url: 'https://rpc.mainnet.near.org',
            simpleName: 'official rpc',
          },
          publicRpc: {
            url: 'https://public-rpc.blockpi.io/http/near',
            simpleName: 'blockpi rpc',
          },
        },
      }
    case 'development':
    case 'pub-testnet':
      return {
        RPC_LIST: {
          defaultRpc: {
            url: 'https://rpc.testnet.near.org',
            simpleName: 'official rpc',
          },
          publicRpc: {
            url: 'https://public-rpc.blockpi.io/http/near-testnet',
            simpleName: 'blockpi rpc',
          },
        },
      }
    case 'testnet':
      return {
        RPC_LIST: {
          defaultRpc: {
            url: 'https://rpc.testnet.near.org',
            simpleName: 'official rpc',
          },
          publicRpc: {
            url: 'https://public-rpc.blockpi.io/http/near-testnet',
            simpleName: 'blockpi rpc',
          },
        },
      }
    default:
      return {
        RPC_LIST: {
          defaultRpc: {
            url: 'https://rpc.mainnet.near.org',
            simpleName: 'official rpc',
          },
          publicRpc: {
            url: 'https://public-rpc.blockpi.io/http/near',
            simpleName: 'blockpi rpc',
          },
        },
      }
  }
}
export default function getConfig(env: string = process.env.NEAR_ENV) {
  const RPC_LIST = getExtendConfig().RPC_LIST
  let endPoint = 'defaultRpc'
  try {
    endPoint = window.localStorage.getItem('endPoint') || endPoint
  } catch (error) {}
  switch (env) {
    case 'production':
    case 'mainnet':
      // return {
      //   networkId: 'mainnet',
      //   nodeUrl: RPC_LIST[endPoint].url,
      //   walletUrl: 'https://wallet.near.org',
      //   helperUrl: 'https://helper.mainnet.near.org',
      //   explorerUrl: 'https://nearblocks.io',
      //   indexerUrl: 'https://indexer.ref-finance.net',
      //   sodakiApiUrl: 'https://api.stats.ref.finance/api',
      //   N_STABLE_CONTRACT_ID: process.env.N_STABLE_CONTRACT_ID || 'v2.ref-finance.near',
      //   WRAP_NEAR_CONTRACT_ID: process.env.WRAP_NEAR_CONTRACT_ID || 'wrap.near',
      //   REF_FARM_CONTRACT_ID: process.env.REF_FARM_CONTRACT_ID || 'v2.ref-farming.near',
      //   TOP_POOLS_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 60,
      //   POOL_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 20,
      //   STABLE_POOL_USN_ID: process.env.STABLE_POOL_USN_ID || 3020,
      //   STABLE_TOKEN_USN_IDS: ['usn', 'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near'],
      //   STABLE_TOKEN_USN_INDEX: {
      //     usn: 0,
      //     'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near': 1,
      //   },
      //   STABLE_POOL_ID: process.env.STABLE_POOL_ID || 1910,
      //   STABLE_POOL_IDS: process.env.STABLE_POOL_IDS || ['1910', '3020'],
      //   STABLE_TOKEN_IDS: [
      //     'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near',
      //     'a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.factory.bridge.near',
      //     '6b175474e89094c44da98b954eedeac495271d0f.factory.bridge.near',
      //   ],
      //   STABLE_TOKEN_INDEX: {
      //     'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near': 0,
      //     'a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.factory.bridge.near': 1,
      //     '6b175474e89094c44da98b954eedeac495271d0f.factory.bridge.near': 2,
      //   },
      //   USN_ID: 'usn',
      //   BLACKLIST_POOL_IDS: ['3364'],
      // }
      return {
        networkId: 'testnet',
        nodeUrl: RPC_LIST[endPoint].url,
        walletUrl: 'https://wallet.testnet.near.org',
        helperUrl: 'https://helper.testnet.near.org',
        kitwalletUrl: 'https://testnet-api.kitwallet.app',
        explorerUrl: 'https://explorer.testnet.near.org',
        indexerUrl: 'https://dev-indexer.ref-finance.com',
        sodakiApiUrl: 'https://api.stats.ref.finance/api',
        nearfiApiUrl: 'https://api-testnet.nearfi.finance',
        N_STABLE_CONTRACT_ID: process.env.N_STABLE_CONTRACT_ID || 'swap.deganstable.testnet',
        NAI_CONTRACT_ID: 'naistable.deganstable.testnet',
        NST_CONTRACT_ID: 'nst.deganstable.testnet',
        VENST_CONTRACT_ID: 'venstnft.deganstable.testnet',
        LENDING_CONTRACT_ID: 'np2pv3.deganstable.testnet',
        WRAP_NEAR_CONTRACT_ID: process.env.WRAP_NEAR_CONTRACT_ID || 'wrap.testnet',
        REF_FARM_CONTRACT_ID: process.env.REF_FARM_CONTRACT_ID || 'farm110.ref-dev.testnet',
        TOP_POOLS_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 60,
        POOL_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 20,
        STABLE_POOL_ID: process.env.STABLE_POOL_ID || 0,
        STABLE_POOL_IDS: process.env.STABLE_POOL_IDS || 0,
        USN_ID: 'usdn.testnet',
        STABLE_POOL_USN_ID: process.env.STABLE_POOL_USN_ID || 0,
        STABLE_TOKEN_IDS: ['usdt.fakes.testnet', 'usdc.fakes.testnet', 'dai.fakes.testnet', 'naistable.deganstable.testnet', 'usdn.testnet'],
        STABLE_TOKEN_USN_IDS: ['usdn.testnet', 'usdt.fakes.testnet'],
        STABLE_TOKEN_USN_INDEX: {
          'usdn.testnet': 0,
          'usdt.fakes.testnet': 1,
        },
        STABLE_TOKEN_INDEX: {
          'usdt.fakes.testnet': 0,
          'usdc.fakes.testnet': 1,
          'dai.fakes.testnet': 2,
          'naistable.deganstable.testnet': 3,
          'usdn.testnet': 4,
        },
        BLACKLIST_POOL_IDS: ['604', '608'],
      }
    case 'testnet':
      return {
        networkId: 'testnet',
        nodeUrl: RPC_LIST[endPoint].url,
        walletUrl: 'https://wallet.testnet.near.org',
        helperUrl: 'https://helper.testnet.near.org',
        kitwalletUrl: 'https://testnet-api.kitwallet.app',
        explorerUrl: 'https://explorer.testnet.near.org',
        indexerUrl: 'https://dev-indexer.ref-finance.com',
        sodakiApiUrl: 'https://api.stats.ref.finance/api',
        nearfiApiUrl: 'https://api-testnet.nearfi.finance',
        N_STABLE_CONTRACT_ID: process.env.N_STABLE_CONTRACT_ID || 'swap.deganstable.testnet',
        NAI_CONTRACT_ID: 'naistable.deganstable.testnet',
        NST_CONTRACT_ID: 'nst.deganstable.testnet',
        VENST_CONTRACT_ID: 'venstnft.deganstable.testnet',
        LENDING_CONTRACT_ID: 'np2pv3.deganstable.testnet',
        WRAP_NEAR_CONTRACT_ID: process.env.WRAP_NEAR_CONTRACT_ID || 'wrap.testnet',
        REF_FARM_CONTRACT_ID: process.env.REF_FARM_CONTRACT_ID || 'farm110.ref-dev.testnet',
        TOP_POOLS_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 60,
        POOL_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 20,
        STABLE_POOL_ID: process.env.STABLE_POOL_ID || 0,
        STABLE_POOL_IDS: process.env.STABLE_POOL_IDS || 0,
        USN_ID: 'usdn.testnet',
        STABLE_POOL_USN_ID: process.env.STABLE_POOL_USN_ID || 0,
        STABLE_TOKEN_IDS: ['usdt.fakes.testnet', 'usdc.fakes.testnet', 'dai.fakes.testnet', 'naistable.deganstable.testnet'],
        STABLE_TOKEN_USN_IDS: ['usdn.testnet', 'usdt.fakes.testnet'],
        STABLE_TOKEN_USN_INDEX: {
          'usdn.testnet': 0,
          'usdt.fakes.testnet': 1,
        },
        STABLE_TOKEN_INDEX: {
          'usdt.fakes.testnet': 0,
          'usdc.fakes.testnet': 1,
          'dai.fakes.testnet': 2,
          'naistable.deganstable.testnet': 3,
          'usdn.testnet': 4,
        },
        BLACKLIST_POOL_IDS: ['604', '608'],
      }
    default:
      return {
        networkId: 'testnet',
        nodeUrl: RPC_LIST[endPoint].url,
        walletUrl: 'https://wallet.testnet.near.org',
        helperUrl: 'https://helper.testnet.near.org',
        kitwalletUrl: 'https://testnet-api.kitwallet.app',
        explorerUrl: 'https://explorer.testnet.near.org',
        indexerUrl: 'https://dev-indexer.ref-finance.com',
        sodakiApiUrl: 'https://api.stats.ref.finance/api',
        nearfiApiUrl: 'https://api-testnet.nearfi.finance',
        N_STABLE_CONTRACT_ID: process.env.N_STABLE_CONTRACT_ID || 'swap.deganstable.testnet',
        NAI_CONTRACT_ID: 'naistable.deganstable.testnet',
        NST_CONTRACT_ID: 'nst.deganstable.testnet',
        VENST_CONTRACT_ID: 'venstnft.deganstable.testnet',
        LENDING_CONTRACT_ID: 'np2pv3.deganstable.testnet',
        WRAP_NEAR_CONTRACT_ID: process.env.WRAP_NEAR_CONTRACT_ID || 'wrap.testnet',
        REF_FARM_CONTRACT_ID: process.env.REF_FARM_CONTRACT_ID || 'farm110.ref-dev.testnet',
        TOP_POOLS_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 60,
        POOL_TOKEN_REFRESH_INTERVAL: process.env.POOL_TOKEN_REFRESH_INTERVAL || 20,
        STABLE_POOL_ID: process.env.STABLE_POOL_ID || 0,
        STABLE_POOL_IDS: process.env.STABLE_POOL_IDS || 0,
        USN_ID: 'usdn.testnet',
        STABLE_POOL_USN_ID: process.env.STABLE_POOL_USN_ID || 0,
        STABLE_TOKEN_IDS: ['usdt.fakes.testnet', 'usdc.fakes.testnet', 'dai.fakes.testnet', 'naistable.deganstable.testnet'],
        STABLE_TOKEN_USN_IDS: ['usdn.testnet', 'usdt.fakes.testnet'],
        STABLE_TOKEN_USN_INDEX: {
          'usdn.testnet': 0,
          'usdt.fakes.testnet': 1,
        },
        STABLE_TOKEN_INDEX: {
          'usdt.fakes.testnet': 0,
          'usdc.fakes.testnet': 1,
          'dai.fakes.testnet': 2,
          'naistable.deganstable.testnet': 3,
          'usdn.testnet': 4,
        },
        BLACKLIST_POOL_IDS: ['604', '608'],
      }
  }
}

export function getExtraStablePoolConfig(env: string = process.env.NEAR_ENV) {
  switch (env) {
    case 'production':
    case 'mainnet':
      return {
        CUSDIDS: [''],
      }
    case 'development':
    case 'testnet':
      return {
        CUSDIDS: ['usdn.testnet', 'cusd.fakes.testnet'],
      }
    default:
      return {
        CUSDIDS: [''],
      }
  }
}
