import { useContext, useEffect, useState } from 'react'
import { getBorrowTokenList, getLiquidationForAccount } from '~services/nai'
import { getCurrentWallet, WalletContext } from '~utils/sender-wallet'

export const useLiquidationsAccount = () => {
  const [liquidations, setLiquidations] = useState([])
  const [totalTransaction, setTotalTransaction] = useState<number>(0)
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  const { wallet, wallet_type } = getCurrentWallet()

  useEffect(() => {
    if (isSignedIn && wallet.account) {
      getLiquidationForAccount().then(setLiquidations)
    } else {
      setLiquidations([])
    }
  }, [isSignedIn, wallet_type, wallet.account])

  useEffect(() => {
    if (liquidations.length > 0) setTotalTransaction(liquidations.length)
  }, [liquidations])
  return { liquidations, totalTransaction }
}

export const useBorrowTokenList = () => {
  const [borrowTokenList, setBorrowTokenList] = useState([])

  useEffect(() => {
    getBorrowTokenList().then(setBorrowTokenList)
  }, [])
  return borrowTokenList
}
