import React, { ReactNode } from 'react'
import { FaDiscord, FaTelegramPlane, FaTwitter } from 'react-icons/fa'
import { useIntl } from 'react-intl'
import {
  IconDocs,
} from '~components/icon/Nav'

export type MenuItem = {
  id: number
  label: string
  url: string
  icon?: ReactNode
  isExternal: boolean
  children?: MenuItem[]
  logo?: ReactNode
  language?: string
}
export const useMenuItems = () => {
  const intl = useIntl()

  const menuData: any[] = [
    {
      label: intl.formatMessage({ id: 'Twitter' }),
      url: 'https://twitter.com/nStable_finance',
      isExternal: true,
      id: 1,
      logo: <FaTwitter />,
    },
    {
      label: intl.formatMessage({ id: 'Telegram' }),
      url: 'https://t.me/nStable_finance',
      isExternal: true,
      id: 2,
      logo: <FaTelegramPlane />,
    },
    {
      label: intl.formatMessage({ id: 'docs' }),
      url: 'https://docs.nstable.finance',
      isExternal: true,
      id: 3,
      logo: <IconDocs />,
    },
  ]

  return { menuData }
}

export type MobileMenuItem = {
  id: string
  label: string
  url: string
  icon?: ReactNode
  isExternal: boolean
  children?: MobileMenuItem[]
  logo?: ReactNode
  pattern?: string
  tip?: string
  subRoute?: string[]
  newFunction?: boolean
  showIcon?: boolean
  iconElement?: ReactNode
}
export const moreLinks: MobileMenuItem[] = [
  {
    id: 'Swap',
    label: 'Swap',
    pattern: '/',
    url: '/',
    isExternal: false,
  },
  {
    id: 'borrow',
    label: 'borrow',
    url: '',
    subRoute: ['/borrow', '/borrow/history'],
    isExternal: false,
    children: [
      {
        id: 'view_borrow',
        label: 'Borrow',
        url: '/borrow',
        pattern: '/borrow',
        isExternal: false,
      },
      {
        id: 'Liquidation_History',
        label: 'Liquidation History',
        url: '/borrow/history',
        pattern: '/borrow/history',
        isExternal: false,
      },
    ],
  },
  {
    id: 'Lending',
    label: 'Lending',
    pattern: '/lending',
    url: '/lending',
    isExternal: false,
  },
  {
    id: 'Option_Trading',
    label: 'Option Trading',
    pattern: '/option-trading',
    url: '/option-trading',
    isExternal: false,
  },
  {
    id: 'veNST',
    label: 'veNST',
    pattern: '/venst',
    url: '/venst',
    isExternal: false,
  },
  {
    id: 'social',
    label: 'Social NearFI',
    url: 'https://social.nearfi.finance/#/',
    isExternal: true,
  },
  {
    id: 'docs',
    label: 'docs',
    url: 'https://docs.nstable.finance/',
    isExternal: true,
    logo: <IconDocs />,
  },
  {
    logo: <FaDiscord />,
    label: 'Discord',
    id: 'Discord',
    url: 'https://discord.gg/',
    isExternal: true,
  },
  {
    logo: <FaTelegramPlane />,
    label: 'Telegram',
    id: 'Telegram',
    url: 'https://t.me/nstable_finance',
    isExternal: true,
  },
  {
    logo: <FaTwitter />,
    id: 'Twitter',
    label: 'Twitter',
    url: 'https://twitter.com/nstable_finance',
    isExternal: true,
  },
]
