import React, { useContext, useEffect, useState } from 'react'
import { Card } from '~components/card/Card'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import { MdHelpOutline } from 'react-icons/md/'
import TokenAmount from '~components/forms/TokenAmount'
import { useTokenBalances, useWalletTokenBalance } from '~state/token'

// import Loading from '../components/layout/Loading'
import { getTokenPriceList } from '~services/indexer'
import { ftGetBalance, ftGetTokenMetadata, TokenMetadata } from '~services/ft-contract'
import { toPrecision, toReadableNumber, toRoundedReadableNumber } from '~utils/numbers'
import { getAccountName, getCurrentWallet, WalletContext } from '~utils/sender-wallet'
import SubmitButton from '~components/forms/SubmitButton'

const BORROW_TOKEN_IN_KEY = 'BORROW_TOKEN_IN'
const { wallet } = getCurrentWallet()

const accountId = getAccountName(wallet.getAccountId())

import {
  getBorrowTokenList,
  getCurrentBorrowInfo,
  getCurrentBorrowInfoForCollateral,
  NAI_CONTRACT_ID,
  naiBorrow,
  naiMetadata,
  naiRepay,
  naiWithdraw,
} from '~services/nai'
import Loading from '~components/layout/Loading'
import { BigNumber } from 'bignumber.js'
import { isUndefined } from 'lodash'

export enum BORROW_MODE {
  BORROW = 'borrow',
  REPAY = 'repay',
  WITHDRAW = 'withdraw',
}

export enum STATUS_BORROW {
  NORMAL = 'normal',
  RISK = 'risk',
  HIGH = 'high',
}

const ChangeBorrowMode = ({
  borrowMode,
  setBorrowMode,
}: {
  borrowMode: BORROW_MODE
  setBorrowMode: (e?: any) => void
}) => {
  return (
    <div
      className="rounded-2xl bg-cardBg text-primaryText text-lg flex items-center justify-between p-1 w-full mx-auto font-normal"
      style={{
        height: '50px',
      }}
    >
      <span
        className={`py-2 w-1/2 text-center cursor-pointer ${
          borrowMode === BORROW_MODE.BORROW ? 'bg-tabChosen rounded-xl' : ''
        }`}
        onClick={() => {
          setBorrowMode(BORROW_MODE.BORROW)
        }}
      >
        <FormattedMessage id="borrow" defaultMessage="Borrow" />
      </span>
      <span
        className={`py-2 w-1/2 text-center cursor-pointer ${
          borrowMode === BORROW_MODE.REPAY ? 'bg-tabChosen rounded-xl' : ''
        }`}
        onClick={() => {
          setBorrowMode(BORROW_MODE.REPAY)
        }}
      >
        <FormattedMessage id="repay" defaultMessage="Repay" />
      </span>
      <span
        className={`py-2 w-1/2 text-center cursor-pointer ${
          borrowMode === BORROW_MODE.WITHDRAW ? 'bg-tabChosen rounded-xl' : ''
        }`}
        onClick={() => {
          setBorrowMode(BORROW_MODE.WITHDRAW)
        }}
      >
        <FormattedMessage id="withdraw" defaultMessage="Withdraw" />
      </span>
    </div>
  )
}

function BorrowTab({
  borrowMode,
  setBorrowMode,
}: {
  borrowMode: BORROW_MODE
  setBorrowMode: (e?: BORROW_MODE) => void
}) {
  const TabTitle = () => {
    return <ChangeBorrowMode borrowMode={borrowMode} setBorrowMode={setBorrowMode} />
  }

  return (
    <div className="w-full mb-5 flex items-center justify-between">
      <TabTitle />
    </div>
  )
}

function BorrowPage() {
  const [borrowMode, setBorrowMode] = useState(BORROW_MODE.BORROW)
  const [metadata, setMetadata] = useState([])

  const [liquidationPrice, setLiquidationPrice] = useState<string>('0')
  const [liquidationPriceCurrent, setLiquidationPriceCurrent] = useState<string>('0')

  const [collateralRatio, setCollateralRatio] = useState<number>(0)
  const [collateralRatioCurrent, setCollateralRatioCurrent] = useState<number>(0)

  const [minCollateralRatio, setMinCollateralRatio] = useState<number | string>(0)
  const [currentPrice, setCurrentPrice] = useState<string>('0')
  const [collateralLocked, setCollateralLocked] = useState<string>('')
  const [liquidationFee, setLiquidationFee] = useState<string>('0')
  const [deposited, setDeposited] = useState<string>('0')
  const [dustLimit, setDustLimit] = useState<string>('100')
  const [borrowed, setBorrowed] = useState<string>('')
  const [maxWithdrawable, setMaxWithdrawable] = useState<string>('')

  const [currentBorrowInfo, setCurrentBorrowInfo] = useState<any>({})
  const [currentBorrowInfoForCollateral, setCurrentBorrowInfoForCollateral] = useState<any>({})

  const [maxBorrowable, setMaxBorrowable] = useState<any>()
  const [borrowTokenIn, setBorrowTokenIn] = useState<TokenMetadata>()

  const [borrowTokenId, setBorrowTokenId] = useState<string>('')
  const [borrowTokenSymbol, setBorrowTokenSymbol] = useState<string>('')
  const [borrowTokenDecimals, setBorrowTokenDecimals] = useState<number>(0)
  const [tokenInAmount, setTokenInAmount] = useState<string>('')
  const [withdrawAmount, setWithdrawAmount] = useState<string>('')
  const [payAmount, setPayAmount] = useState<string>('')
  const [borrowAmount, setBorrowAmount] = useState<string>('')
  const [tokenInBalanceFromNear, setTokenInBalanceFromNear] = useState<string>()
  const [tokenPriceList, setTokenPriceList] = useState<Record<string, any>>({})

  const [status, setStatus] = useState<STATUS_BORROW>(STATUS_BORROW.NORMAL)
  const [currentStatus, setCurrentStatus] = useState<STATUS_BORROW>(STATUS_BORROW.NORMAL)
  const [canBorrow, setCanBorrow] = useState<boolean>(false)
  const [canRepay, setCanRepay] = useState<boolean>(false)
  const [canWithdraw, setCanWithdraw] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  const intl = useIntl()
  const balances = useTokenBalances()

  const _naiBalance = useWalletTokenBalance(NAI_CONTRACT_ID)
  const naiBalance = toReadableNumber(18, _naiBalance)

  useEffect(() => {
    getTokenPriceList().then(setTokenPriceList)
  }, [])

  useEffect(() => {
    getBorrowTokenList()
      .then((tokenIds) => {
        // @ts-ignore
        return Promise.all(tokenIds?.map((tokenId) => ftGetTokenMetadata(tokenId)))
      })
      .then(setMetadata)
  }, [getCurrentWallet().wallet.isSignedIn()])

  useEffect(() => {
    setBorrowTokenIn(metadata[0])
    setBorrowTokenId(metadata[0]?.id)
    setBorrowTokenSymbol(metadata[0]?.symbol)
    setBorrowTokenDecimals(metadata[0]?.decimals)
  }, [metadata])

  useEffect(() => {
    getCurrentBorrowInfo(borrowTokenId)
      .then(setCurrentBorrowInfo)
      .catch(() => setCurrentBorrowInfo({}))
  }, [isSignedIn, borrowTokenId])

  console.log('currentBorrowInfoForCollateral', currentBorrowInfoForCollateral)

  useEffect(() => {
    const _liquidationPriceCurrent = toRoundedReadableNumber({
      decimals: currentBorrowInfo[0]?.liquidation_price?.decimals,
      number: currentBorrowInfo[0]?.liquidation_price?.multiplier,
      precision: 3,
    })

    // const _collateralRatioCurrent = toRoundedReadableNumber({
    //   decimals: 2,
    //   number: currentBorrowInfo[0]?.current_collateral_ratio?.toString(),
    //   precision: 3,
    // })

    const _collateralRatioCurrent = Number(currentBorrowInfo[0]?.current_collateral_ratio) / 100

    const _deposited = toRoundedReadableNumber({
      decimals: currentBorrowInfo[0]?.decimals,
      number: currentBorrowInfo[0]?.deposited,
      precision: 3,
    })

    setLiquidationPriceCurrent(_liquidationPriceCurrent)
    if (isNaN(_collateralRatioCurrent)) {
      setCollateralRatioCurrent(0)
    } else {
      setCollateralRatioCurrent(_collateralRatioCurrent)
    }
    setDeposited(_deposited)
  }, [currentBorrowInfo])

  useEffect(() => {
    getCurrentBorrowInfoForCollateral({
      tokenId: borrowTokenId,
      collateral_amount: tokenInAmount,
      borrow: borrowAmount,
      decimals: borrowTokenDecimals,
      pay_amount: payAmount,
    })
      .then(setCurrentBorrowInfoForCollateral)
      .catch(() => setCurrentBorrowInfoForCollateral({}))
  }, [isSignedIn, borrowTokenId, tokenInAmount, borrowAmount, payAmount])

  useEffect(() => {
    const _currentPrice = toRoundedReadableNumber({
      decimals: currentBorrowInfoForCollateral?.collateral_token_price_decimal,
      number: currentBorrowInfoForCollateral?.collateral_token_price,
      precision: 3,
    })

    const _minCollateralRatio = currentBorrowInfoForCollateral?.collateral_ratio
      ? Number(currentBorrowInfoForCollateral?.collateral_ratio) / 100
      : 0

    const _collateralRatio = currentBorrowInfoForCollateral?.current_collateral_ratio
      ? Number(currentBorrowInfoForCollateral?.current_collateral_ratio) / 100
      : 0

    const _liquidationPrice = toRoundedReadableNumber({
      decimals: currentBorrowInfoForCollateral?.liquidation_price?.decimals,
      number: currentBorrowInfoForCollateral?.liquidation_price?.multiplier,
      precision: 3,
    })

    const _maxBorrowable = new BigNumber(
      currentBorrowInfoForCollateral?.max_borrowable > 0 ? currentBorrowInfoForCollateral?.max_borrowable : '0',
    )
      .dividedBy(new BigNumber(10 ** 18))
      .toString()

    const _liquidationFee = toRoundedReadableNumber({
      decimals: 2,
      number: currentBorrowInfoForCollateral?.liquidation_fee?.toString(),
      precision: 0,
    })

    const _dustLimit = toRoundedReadableNumber({
      decimals: 18,
      number: currentBorrowInfoForCollateral?.dust_limit,
      precision: 0,
    })

    const _collateralLocked = toRoundedReadableNumber({
      decimals: currentBorrowInfoForCollateral?.decimals,
      number: currentBorrowInfoForCollateral?.collateral_value,
      precision: 3,
    })

    const _borrowed = toRoundedReadableNumber({
      decimals: 18,
      number: currentBorrowInfoForCollateral?.borrowed,
      precision: 3,
    })

    const _maxWithdrawable = new BigNumber(
      currentBorrowInfoForCollateral?.max_withdrawable > 0 ? currentBorrowInfoForCollateral?.max_withdrawable : '0',
    )
      .dividedBy(new BigNumber(10 ** currentBorrowInfoForCollateral?.decimals))
      .toString()

    setMaxBorrowable(_maxBorrowable)
    setCurrentPrice(_currentPrice)
    setMinCollateralRatio(_minCollateralRatio)
    setCollateralRatio(_collateralRatio)
    setLiquidationPrice(_liquidationPrice)
    setLiquidationFee(_liquidationFee)
    if (_dustLimit === '0') {
      setDustLimit('100')
    } else {
      setDustLimit(_dustLimit)
    }
    setCollateralLocked(_collateralLocked)
    setBorrowed(_borrowed)
    if (isNaN(Number(_maxWithdrawable))) {
      setMaxWithdrawable('0')
    } else {
      setMaxWithdrawable(_maxWithdrawable)
    }
  }, [currentBorrowInfoForCollateral])

  useEffect(() => {
    const _risk = Number(collateralRatio) / Number(minCollateralRatio)
    if (_risk > 1.2) {
      setStatus(STATUS_BORROW.NORMAL)
    } else if (_risk > 1.1) {
      setStatus(STATUS_BORROW.RISK)
    } else {
      setStatus(STATUS_BORROW.HIGH)
    }
  }, [collateralRatio, minCollateralRatio])

  useEffect(() => {
    const _risk = Number(collateralRatioCurrent) / Number(minCollateralRatio)
    if (_risk > 1.2) {
      setCurrentStatus(STATUS_BORROW.NORMAL)
    } else if (_risk > 1.1) {
      setCurrentStatus(STATUS_BORROW.RISK)
    } else {
      setCurrentStatus(STATUS_BORROW.HIGH)
    }
  }, [collateralRatioCurrent, minCollateralRatio])

  useEffect(() => {
    if (Number(tokenInAmount) > 0 || Number(borrowAmount) > 100) {
      setCanBorrow(true)
    } else {
      setCanBorrow(false)
    }
  }, [tokenInAmount, borrowAmount])

  useEffect(() => {
    if (Number(borrowed) > 0 && Number(payAmount) > 0 && Number(borrowed) >= Number(payAmount)) {
      setCanRepay(true)
    } else {
      setCanRepay(false)
    }
  }, [borrowed, payAmount])

  useEffect(() => {
    if (Number(withdrawAmount) > 0 && Number(maxWithdrawable) >= Number(withdrawAmount)) {
      setCanWithdraw(true)
    } else {
      setCanWithdraw(false)
    }
  }, [withdrawAmount, maxWithdrawable])

  useEffect(() => {
    if (borrowTokenIn) {
      const tokenInId = borrowTokenIn.id
      if (tokenInId) {
        if (isSignedIn) {
          ftGetBalance(tokenInId).then((available: string) =>
            setTokenInBalanceFromNear(toReadableNumber(borrowTokenIn?.decimals, available)),
          )
        }
      }
    }
  }, [borrowTokenIn, isSignedIn])

  const tokenInMax = tokenInBalanceFromNear || '0'

  const handleBorrowSubmit = () => {
    setButtonLoading(true)
    return naiBorrow({
      accountId,
      tokenAmounts: [
        {
          token: borrowTokenIn,
          amount: tokenInAmount,
        },
      ],
      collateral_token_id: borrowTokenId,
      borrow_amount: borrowAmount,
    })
  }

  const onRePayAmount = (amount: React.SetStateAction<string>) => {
    setPayAmount(amount)
  }

  const handleRepaySubmit = () => {
    setButtonLoading(true)
    return naiRepay({
      accountId,
      tokenAmounts: [
        {
          token: naiMetadata,
          amount: tokenInAmount,
        },
      ],
      collateral_token_id: borrowTokenId,
      pay_amount: payAmount,
    })
  }

  const onWithdrawAmount = (amount: React.SetStateAction<string>) => {
    setWithdrawAmount(amount)
  }

  const handleWithdrawSubmit = () => {
    setButtonLoading(true)
    return naiWithdraw({
      collateral_token_id: borrowTokenId,
      withdraw_amount: withdrawAmount,
      token_decimals: borrowTokenDecimals,
    })
  }

  if (!metadata) return <Loading />

  return (
    <div className="max-w-5xl flex flex-col mx-auto items-center -mt-5 xs:px-4 md:px-4 xs:w-full lg:w-4/5 xl:w-4/6">
      <div className="w-full">
        <h2 className="mt-10 mb-8 text-2xl text-left font-medium">{`NAI${
          borrowTokenSymbol ? '-' + borrowTokenSymbol : ''
        } Vault`}</h2>
      </div>
      <div className="w-full">
        <div className="xs:mb-2.5 md:mb-2.5 lg:mb-3 grid lg:grid-cols-borrowColumn gap-4 xs:grid-rows-borrowColumn md:grid-rows-borrowColumn">
          <Card
            width="w-full"
            padding="px-6 py-8"
            bgcolor="bg-secondary"
            className="text-primaryText outline-none shadow-4xl order-last lg:order-1"
          >
            <InfoLine
              title="Liquidation Fee:"
              value={liquidationFee}
              unit="%"
              tip="If your vault is liquidated, a liquidation penalty is added to your vault’s total outstanding debt in Nai, which results in more collateral being sold to cover the outstanding debt. This is done to incentive vaults owners to avoid liquidation. The size of the liquidation penalty is determined by the Liquidation Fee which is calculated based on the debt in the vault."
            />
            <InfoLine
              title="Min. collateral ratio:"
              value={minCollateralRatio}
              unit="%"
              tip="If the collateralization ratio of your vault fall belows the minimum collateralization ratio of the vault, your respective collateralized asset will be liquidated to cover the outstanding debt. Your collateralized asset will be liquidated until the collateralization ratio of your vault becomes equal or above the minimum collateralization ratio"
            />
            <InfoLine
              title="Dust Limit:"
              value={dustLimit}
              unit="$"
              tip="The minimum amount of NAI to borrow for your vault"
            />
            <InfoLine
              title="Deposited:"
              value={deposited}
              unit={` ${borrowTokenSymbol}`}
              tip="The Maker Protocol collects a Stability Fee on Nai that is generated from Maker Vaults. It is a variable-rate fee that changes when Maker’s governing body votes on proposals put forth by Risk Teams."
            />
            <InfoLine title="Borrowed:" value={borrowed} unit=" NAI" tip="The mount of your borrowed NAI" />
            <InfoLine
              title="Available to Withdraw:"
              value={toPrecision(maxWithdrawable, 3, true)}
              unit={` ${borrowTokenSymbol}`}
              tip="The mount of your withdrawable"
            />
          </Card>
          <Card
            width="w-full"
            padding="px-6 py-8"
            bgcolor="bg-secondary"
            className="text-primaryText outline-none shadow-4xl order-first lg:order-last"
          >
            <BorrowTab setBorrowMode={setBorrowMode} borrowMode={borrowMode} />
            {borrowMode === BORROW_MODE.BORROW && (
              <>
                <TokenAmount
                  forBorrow
                  amount={tokenInAmount}
                  total={tokenInMax}
                  max={tokenInMax}
                  tokens={metadata}
                  selectedToken={borrowTokenIn}
                  balances={balances}
                  onSelectToken={(token) => {
                    localStorage.setItem(BORROW_TOKEN_IN_KEY, token.id)
                    setBorrowTokenIn(token)
                    setBorrowTokenId(token.id)
                    setBorrowTokenSymbol(token.symbol)
                    setBorrowTokenDecimals(token.decimals)
                    setTokenInBalanceFromNear(token?.near?.toString())
                  }}
                  text={intl.formatMessage({ id: 'deposit' })}
                  onChangeAmount={(amount) => {
                    setTokenInAmount(amount)
                  }}
                  tokenPriceList={tokenPriceList}
                />
                <div className="mb-4" />
                <TokenAmount
                  amount={borrowAmount}
                  max={maxBorrowable}
                  total={maxBorrowable}
                  showSelectToken={false}
                  balanceText={intl.formatMessage({ id: 'borrow_max' })}
                  selectedToken={naiMetadata}
                  text={intl.formatMessage({ id: 'generate_nai' })}
                  onChangeAmount={(amount) => {
                    setBorrowAmount(amount)
                  }}
                />
                <SubmitButton
                  loading={buttonLoading}
                  disabled={!canBorrow}
                  onClick={handleBorrowSubmit}
                  label="borrow"
                />
              </>
            )}
            {borrowMode === BORROW_MODE.REPAY && (
              <>
                <TokenAmount
                  amount={payAmount}
                  max={borrowed}
                  total={naiBalance}
                  showSelectToken={false}
                  balances={balances}
                  selectedToken={naiMetadata}
                  text={intl.formatMessage({ id: 'repay' })}
                  onChangeAmount={onRePayAmount}
                />
                <SubmitButton loading={buttonLoading} disabled={!canRepay} onClick={handleRepaySubmit} label="repay" />
              </>
            )}
            {borrowMode === BORROW_MODE.WITHDRAW && (
              <>
                <TokenAmount
                  amount={withdrawAmount}
                  total={maxWithdrawable}
                  max={maxWithdrawable}
                  selectedToken={borrowTokenIn}
                  showSelectToken={false}
                  balanceText={intl.formatMessage({ id: 'max_withdrawable' })}
                  text={intl.formatMessage({ id: 'withdraw' })}
                  onChangeAmount={onWithdrawAmount}
                />
                <SubmitButton
                  loading={buttonLoading}
                  disabled={!canWithdraw}
                  onClick={handleWithdrawSubmit}
                  label="withdraw"
                />
              </>
            )}
          </Card>
        </div>
      </div>
      <div className="w-full grid mt-3 grid lg:grid-cols-2 md:grid-cols-2 grid-rows-1 lg:grid-rows-2 gap-4">
        <InfoBox
          title="Liquidation Price"
          value={liquidationPriceCurrent}
          valueChange={liquidationPrice}
          unit="$"
          tip="The Liquidation Price is the price at which a Vault becomes vulnerable to liquidation."
          status={status}
          currentStatus={currentStatus}
        />
        <InfoBox
          title="Collateralization Ratio"
          value={collateralRatioCurrent}
          valueChange={collateralRatio}
          unit="%"
          tip="Your collateralization ratio is calculated by: value of your collateral / Nai debt."
          status={status}
          currentStatus={currentStatus}
        />
        <InfoBox
          title="Current Price"
          value={currentPrice}
          unit="$"
          tip="This is the current price of your vault collateral supplied by the Maker price oracles. There is a one hour delay before the oracle price is made current in the Maker Protocol."
        />
        <InfoBox
          title="Collateral Locked"
          value={collateralLocked}
          unit="$"
          tip="We use the current price from the Maker oracles to calculate this value. The current market price of your collateral may differ from this price."
        />
      </div>
    </div>
  )
}

function InfoBox(props: any) {
  const { title, value = 0, valueChange, tip, unit, status, currentStatus } = props
  return (
    <div className="h-38 rounded-lg bg-white shadow-4xl p-6">
      <div className="flex justify-between items-center text-dark capitalize text-md mb-2">
        {title}
        {tip ? (
          <>
            <span
              className="relative top-0.5 inline-block ml-1"
              data-type="info"
              data-place="right"
              data-multiline={true}
              data-class="reactTip"
              data-html={true}
              data-tip={tip}
              data-for="borrowPage"
            >
              <MdHelpOutline size={20} />
            </span>
            <ReactTooltip
              className="w-360px"
              id="borrowPage"
              backgroundColor="#1D2932"
              border
              borderColor="#7e8a93"
              effect="solid"
            />
          </>
        ) : null}
      </div>
      <div className="flex items-center mb-2">
        <label className={`text-xl font-semibold status-current-${currentStatus ? currentStatus : ''}`}>
          {unit === '$' ? unit : null}
          {toPrecision(value.toString(), 3, true)}
          {unit !== '$' ? unit : null}
        </label>
      </div>
      {valueChange && (
        <span className={`rounded-xl text-md inline-block px-3 py-0.5 ${status ? `status-${status}` : ''}`}>
          {unit === '$' ? unit : null}

          {toPrecision(valueChange.toString(), 3, true)}
          {unit !== '$' ? unit : null}
        </span>
      )}
    </div>
  )
}

function InfoLine(props: any) {
  const { title, value, tip, unit } = props
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="text-sm">
        <span className="mr-2 text-darkText">{title}</span>
        <label className="text-primaryText">
          {unit === '$' ? unit : null}
          {value}
          {unit !== '$' ? unit : null}
        </label>
      </div>
      {tip ? (
        <>
          <span
            className="relative inline-block ml-1"
            data-type="info"
            data-place="right"
            data-multiline={true}
            data-class="reactTip"
            data-html={true}
            data-tip={tip}
            data-for="borrowLineInfo"
          >
            <MdHelpOutline size={16} className="text-grayText" />
          </span>
          <ReactTooltip
            className="w-360px"
            id="borrowLineInfo"
            backgroundColor="#1D2932"
            border
            borderColor="#7e8a93"
            effect="solid"
          />
        </>
      ) : null}
    </div>
  )
}

export default BorrowPage
