import BN from 'bn.js'
import { getCurrentWallet } from '~utils/sender-wallet'
import {
  NnStableFunctionCallOptions,
  N_STABLE_CONTRACT_ID,
  REF_FARM_CONTRACT_ID,
  // wallet,
  nStableViewFunction,
  NAI_CONTRACT_ID,
} from '../near'

export const STORAGE_PER_TOKEN = '0.005'
export const STORAGE_TO_REGISTER_WITH_FT = '0.11'
export const STORAGE_TO_REGISTER_WITH_MFT = '0.11'
export const MIN_DEPOSIT_PER_TOKEN = new BN('5000000000000000000000')
export const MIN_DEPOSIT_PER_TOKEN_FARM = new BN('45000000000000000000000')
export const ONE_MORE_DEPOSIT_AMOUNT = '0.1'

interface StorageDepositActionOptions {
  accountId?: string
  registrationOnly?: boolean
  amount: string
}
export const storageDepositAction = ({
  accountId = getCurrentWallet().wallet.getAccountId(),
  registrationOnly = false,
  amount,
}: StorageDepositActionOptions): NnStableFunctionCallOptions => ({
  methodName: 'storage_deposit',
  args: {
    account_id: accountId,
    registration_only: registrationOnly,
  },
  amount,
})

export const storageDepositForTokenAction = (
  accountId: string = getCurrentWallet().wallet.getAccountId(),
): NnStableFunctionCallOptions =>
  storageDepositAction({
    accountId,
    amount: STORAGE_PER_TOKEN,
  })

export const storageDepositForFTAction = () =>
  storageDepositAction({
    accountId: N_STABLE_CONTRACT_ID,
    amount: STORAGE_TO_REGISTER_WITH_FT,
  })

export const storageDepositForMFTAction = () =>
  storageDepositAction({
    accountId: REF_FARM_CONTRACT_ID,
    amount: STORAGE_TO_REGISTER_WITH_MFT,
  })

export const needDepositStorage = async (accountId = getCurrentWallet().wallet.getAccountId()) => {
  const storage = await nStableViewFunction({
    methodName: 'get_user_storage_state',
    args: { account_id: accountId },
  })

  return new BN(storage?.deposit).lte(new BN(storage?.usage))
}
