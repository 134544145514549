import React, { useEffect, useState, useContext } from 'react'
import ContentLoader, { Facebook } from 'react-content-loader'
import { useLocation } from 'react-router-dom'
import Modal from 'react-modal'
import { Card } from '~components/card/Card'
import { usePool, useRemoveLiquidity, useDayVolume, useDepositsPool } from '~state/pool'
import { addLiquidityToPool, Pool } from '~services/pool'
import { useTokens } from '~state/token'
import Loading from '~components/layout/Loading'
import { PoolSlippageSelector } from '~components/forms/SlippageSelector'
import {
  // calculateFairShare,
  calculateFeePercent,
  percent,
  toNonDivisibleNumber,
  toPrecision,
  toReadableNumber,
  toInternationalCurrencySystem,
  toRoundedReadableNumber,
} from '~utils/numbers'
import { TokenMetadata } from '~services/ft-contract'
import Alert from '~components/alert/Alert'
import TokenIcon from '~components/tokenicon/TokenIcon'
import InputAmount from '~components/forms/InputAmount'
import { isMobile } from '~utils/device'
import ReactModal from 'react-modal'
import { toRealSymbol } from '~utils/token'

import { ModalClose } from '~components/icon'
import { getPool } from '~services/indexer'
import { BigNumber } from 'bignumber.js'
import { FormattedMessage, useIntl } from 'react-intl'
import { OutlineButton, SolidButton, ButtonTextWrapper, ConnectToNearBtn } from '~components/button/Button'
import { LP_TOKEN_DECIMALS } from '~services/m-token'

import { WarnTriangle } from '~components/icon/SwapRefresh'
import { getCurrentWallet, WalletContext } from '~utils/sender-wallet'

import { useWalletTokenBalances } from '~state/token'
import { scientificNotationToString } from '~utils/numbers'

export const N_STABLE_PRE_LIQUIDITY_ID_KEY = 'N_STABLE_PRE_LIQUIDITY_ID_VALUE'

interface LocationTypes {
  tvl: number
  backToFarms: boolean
}
const ONLY_ZEROS = /^0*\.?0*$/

function Icon(props: { icon?: string; className?: string; style?: any }) {
  const { icon, className, style } = props
  return icon ? (
    <img
      className={`block ${className} rounded-full`}
      src={icon}
      style={style}
      alt=""
    />
  ) : (
    <div className={`rounded-full ${className} border border-primary border-solid`} style={style} />
  )
}

const _tokenAmounts = [
  {
    token: {
      id: 'usdc.fakes.testnet',
      decimals: 6,
    },
    amount: '',
  },
  {
    token: {
      id: 'usdt.fakes.testnet',
      decimals: 6,
    },
    amount: '',
  },
  {
    token: {
      id: 'dai.fakes.testnet',
      decimals: 18,
    },
    amount: '',
  },
  {
    token: {
      id: 'naistable.deganstable.testnet',
      decimals: 18,
    },
    amount: '',
  },
  {
    token: {
      id: 'usdn.testnet',
      decimals: 18,
    },
    amount: '',
  },
]

export function AddLiquidityModal(
  props: ReactModal.Props & {
    pool: Pool
    tokens: TokenMetadata[]
    closeTip?: boolean
  },
) {
  const { pool, tokens, closeTip } = props
  const [tokenAmounts, setTokenAmounts] = useState<any>(_tokenAmounts)
  const [messageId, setMessageId] = useState<string>('add_liquidity')
  const [defaultMessage, setDefaultMessage] = useState<string>('Add Liquidity')
  const balances = useWalletTokenBalances(tokens.map((token) => token.id))
  const [error, setError] = useState<Error>()
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [canDeposit, setCanDeposit] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [preShare, setPreShare] = useState(null)
  const [modal, setModal] = useState(null)
  const [visible, setVisible] = useState(false)

  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  if (!balances) return null

  const changeTokenAmount = (amount: string, name: string, decimals: string) => {
    setError(null)

    const index = tokenAmounts.findIndex((token: any) => token.token.id === name)
    tokenAmounts[index] = {
      token: {
        id: name,
        decimals: decimals,
      },
      amount: amount,
    }
    console.log('tokenAmounts addLiquidityToPool', tokenAmounts)

    setTokenAmounts(tokenAmounts)
  }

  function submit() {
    return addLiquidityToPool({
      id: 0,
      tokenAmounts: tokenAmounts,
    })
  }

  const ButtonRender = () => {
    if (!isSignedIn) {
      return <ConnectToNearBtn />
    }

    const handleClick = async () => {
      setButtonLoading(true)
      submit()
      localStorage.setItem(N_STABLE_PRE_LIQUIDITY_ID_KEY, '0')
      // if (canSubmit) {
      //   setButtonLoading(true)
      //   submit()
      //   localStorage.setItem(N_STABLE_PRE_LIQUIDITY_ID_KEY, pool.id.toString())
      // }
    }
    return (
      <SolidButton
        // disabled={!canSubmit || canDeposit}
        className="focus:outline-none px-4 w-full"
        onClick={handleClick}
        loading={buttonLoading}
      >
        <div className="flex items-center justify-center w-full m-auto">
          <div>
            <ButtonTextWrapper
              loading={buttonLoading}
              Text={() => <FormattedMessage id={messageId} defaultMessage={defaultMessage} />}
            />
          </div>
        </div>
      </SolidButton>
    )
  }
  const shareDisplay = () => {
    let result = ''
    if (preShare && new BigNumber('0').isLessThan(preShare)) {
      const myShareBig = new BigNumber(preShare)
      if (myShareBig.isLessThan('0.001')) {
        result = '<0.001'
      } else {
        result = `≈ ${myShareBig.toFixed(3)}`
      }
    } else {
      result = '-'
    }
    return result
  }

  return (
    <Modal {...props}>
      <Card
        style={{ width: isMobile() ? '95vw' : '480px' }}
        width="w-10/12"
        padding="px-6 py-8"
        bgcolor="bg-secondary"
        className="text-primaryText outline-none "
      >
        <div className="flex items-start justify-between">
          <div>
            <div className="text-base pb-2">
              <FormattedMessage id="add_liquidity" defaultMessage="Add Liquidity" />
            </div>
          </div>
          <div className="ml-2 cursor-pointer p-1" onClick={props.onRequestClose}>
            <ModalClose />
          </div>
        </div>
        {tokens.length > 1 && (
          <>
            {tokens.map((token, index) => (
              <div key={index} className="mt-4">
                <div className="flex justify-end items-center text-xs text-right mb-1 text-gray-400">
                  <FormattedMessage id="balance" defaultMessage="Balance" />
                  :&nbsp;
                  <span title={toReadableNumber(token.decimals, balances[token.id])}>
                    {toPrecision(toReadableNumber(token.decimals, balances[token.id]), 2, true)}
                  </span>
                </div>
                <div className="flex items-center ">
                  <div className="flex items-center mr-4 w-1/3">
                    <TokenIcon icon={token.icon} className="h-6 w-6 mr-2" />
                    <div className="text-base" title={token.id}>
                      {toRealSymbol(token.symbol)}
                    </div>
                  </div>
                  <InputAmount
                    className="w-full border border-transparent rounded"
                    // max={toReadableNumber(token.decimals, balances[token.id])}
                    onChangeAmount={changeTokenAmount}
                    value={token.amount}
                    name={token.id}
                    data-decimals={token.decimals}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        {error ? (
          <div className="flex justify-center mb-8 ">
            <Alert level="warn" message={error.message} />
          </div>
        ) : null}

        {canDeposit ? (
          <div className="flex xs:flex-col md:flex-col justify-between items-center rounded-md p-4 xs:px-2 md:px-2 border border-warnColor">
            <div className="flex items-center xs:mb-3 md:mb-3">
              <label className="flex-shrink-0">
                <WarnTriangle />
              </label>
              <label className="ml-2.5 text-base text-warnColor xs:text-sm md:text-sm">
                <FormattedMessage id="you_do_not_have_enough" /> {modal?.token?.symbol}！
              </label>
            </div>
            {/* <SolidButton
              className="focus:outline-none px-3 py-1.5 text-sm"
              onClick={() => {
                setVisible(true);
              }}
            >
              <FormattedMessage id="deposit" />
            </SolidButton> */}
          </div>
        ) : null}
        <div className="flex justify-between text-primaryText text-sm my-6">
          <label>
            <FormattedMessage id="my_shares"></FormattedMessage>
          </label>
          <span className="text-sm">{shareDisplay()}</span>
        </div>
        <div className="">
          <ButtonRender />
        </div>
      </Card>
    </Modal>
  )
}

export function RemoveLiquidityModal(
  props: ReactModal.Props & {
    pool: Pool
    shares: string
    tokens: TokenMetadata[]
  },
) {
  const { pool, shares, tokens } = props
  const [amount, setAmount] = useState<string>('')
  const [slippageTolerance, setSlippageTolerance] = useState<number>(0.5)
  const { minimumAmounts, removeLiquidity } = useRemoveLiquidity({
    pool,
    slippageTolerance,
    shares: amount ? toNonDivisibleNumber(24, amount) : '0',
  })
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const intl = useIntl()

  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  function submit() {
    const amountBN = new BigNumber(amount?.toString())
    const shareBN = new BigNumber(toReadableNumber(24, shares))
    if (Number(amount) === 0) {
      throw new Error(intl.formatMessage({ id: 'must_input_a_value_greater_than_zero' }))
    }
    if (amountBN.isGreaterThan(shareBN)) {
      throw new Error(
        intl.formatMessage({
          id: 'input_greater_than_available_shares',
        }),
      )
    }
    setButtonLoading(true)
    localStorage.setItem(N_STABLE_PRE_LIQUIDITY_ID_KEY, pool.id.toString())
    return removeLiquidity()
  }

  function handleChangeAmount(value: string) {
    setAmount(value)
    setError(null)

    const amountBN = new BigNumber(value.toString())
    const shareBN = new BigNumber(toReadableNumber(24, shares))
    if (amountBN.isGreaterThan(shareBN)) {
      setCanSubmit(false)
      throw new Error(
        intl.formatMessage({
          id: 'input_greater_than_available_shares',
        }),
      )
    }
    if (ONLY_ZEROS.test(value)) {
      setCanSubmit(false)
      return
    }
    setCanSubmit(true)
  }
  return (
    <Modal {...props}>
      <Card
        padding="p-8"
        bgcolor="bg-secondary"
        className="rounded-4xl outline-none "
        style={{
          width: isMobile() ? '95vw' : '480px',
        }}
      >
        <div className="flex items-start justify-between">
          <div className="text-base pb-4">
            <FormattedMessage id="remove_liquidity" defaultMessage="Remove Liquidity" />
          </div>
          <div className="ml-2 cursor-pointer p-1" onClick={props.onRequestClose}>
            <ModalClose />
          </div>
        </div>

        <div>
          <div className="text-xs text-right mb-1 text-gray-400">
            <FormattedMessage id="my_shares" defaultMessage="Shares" />
            :&nbsp;
            {toPrecision(toReadableNumber(24, shares), 2)}
          </div>
          <div className=" overflow-hidden ">
            <InputAmount
              maxBorder={false}
              value={amount}
              max={toReadableNumber(24, shares)}
              onChangeAmount={(value) => {
                try {
                  handleChangeAmount(value)
                } catch (error) {
                  setError(error)
                }
              }}
              className="border border-transparent rounded"
            />
          </div>
        </div>
        <div className="pt-4 mb-8">
          <PoolSlippageSelector slippageTolerance={slippageTolerance} onChange={setSlippageTolerance} />
        </div>
        {amount && Number(pool.shareSupply) != 0 ? (
          <>
            <p className="my-3 text-left text-sm">
              <FormattedMessage id="minimum_tokens_out" defaultMessage="Minimum shares" />
            </p>
            <section className="grid grid-cols-2 mb-6 w-full">
              {Object.entries(minimumAmounts).map(([tokenId, minimumAmount], i) => {
                const token = tokens.find((t) => t.id === tokenId)
                return (
                  <section key={tokenId} className="flex flex-col items-center col-span-1">
                    <Icon icon={token.icon} className="h-6 w-6" />
                    <span className="m-1 mb-2 text-sm">{token.symbol} </span>
                    <span className="ml-2 text-base font-bold">
                      {toInternationalCurrencySystem(
                        toPrecision(toReadableNumber(token.decimals, minimumAmount), 4),
                        4,
                      )}
                    </span>
                  </section>
                )
              })}
            </section>
          </>
        ) : null}
        <div className="flex justify-center mb-2">{error && <Alert level="warn" message={error.message} />}</div>
        <div className="">
          {isSignedIn ? (
            <SolidButton
              disabled={!canSubmit}
              className={`focus:outline-none px-4 w-full`}
              onClick={async () => {
                try {
                  await submit()
                } catch (error) {
                  setError(error)
                }
              }}
              loading={buttonLoading}
            >
              <ButtonTextWrapper
                loading={buttonLoading}
                Text={() => <FormattedMessage id="remove_liquidity" defaultMessage="Remove Liquidity" />}
              />
            </SolidButton>
          ) : (
            <ConnectToNearBtn />
          )}
        </div>
      </Card>
    </Modal>
  )
}

function MyShares({
  shares,
  totalShares,
  poolId,
  stakeList = {},
  decimal,
}: {
  shares: string
  totalShares: string
  poolId?: number
  stakeList?: Record<string, string>
  decimal?: number
  yourLP?: boolean
}) {
  if (!shares || !totalShares) return <div>-</div>
  const seedIdList: string[] = Object.keys(stakeList)
  let farmStake: string | number = '0'
  seedIdList.forEach((seed) => {
    const id = Number(seed.split('@')[1])
    if (id == poolId) {
      farmStake = BigNumber.sum(farmStake, stakeList[seed]).valueOf()
    }
  })

  const userTotalShare = BigNumber.sum(shares, farmStake)
  let sharePercent = percent(userTotalShare.valueOf(), totalShares)

  let displayPercent
  if (Number.isNaN(sharePercent) || sharePercent === 0) displayPercent = '0'
  else if (sharePercent < 0.0001) displayPercent = `< ${decimal ? '0.'.padEnd(decimal + 1, '0') + '1' : '0.0001'}`
  else displayPercent = toPrecision(String(sharePercent), decimal || 4)

  return (
    <div>{`${toRoundedReadableNumber({
      decimals: LP_TOKEN_DECIMALS,
      number: userTotalShare.toNumber().toLocaleString('fullwide', { useGrouping: false }),
      precision: decimal || 6,
    })} (${displayPercent}%)`}</div>
  )
}

function AddLiquidityCard() {
  // const { id } = useParams<ParamTypes>()
  const id = '0'
  const { state } = useLocation<LocationTypes>()
  const { pool, shares, stakeList } = usePool(id)
  const deposits = useDepositsPool(id)

  const dayVolume = useDayVolume(id)
  const tokens = useTokens(pool?.tokenIds)
  // TODO: remove
  // console.log(pool)
  // console.log('deposits', deposits)
  // console.log('pool tokens', tokens)

  const [showFunding, setShowFunding] = useState(false)
  const [showWithdraw, setShowWithdraw] = useState(false)
  const [poolTVL, setPoolTVL] = useState<number>()
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  useEffect(() => {
    if (state?.tvl > 0) {
      setPoolTVL(state?.tvl)
    } else {
      getPool(id).then((pool) => {
        setPoolTVL(pool?.tvl)
      })
    }
  }, [])

  if (!pool || !tokens || tokens.length < 2) return <Facebook />

  return (
    <>
      <Card className="rounded-2xl lg:w-480px md:w-3/4 xs:w-full shadow-4xl" padding="p-0" bgcolor="bg-secondary">
        {tokens.length > 1 && (
          <div className="flex flex-col text-center text-base mx-4 pt-10 pb-4">
            {tokens.map((token, index) => (
              <div key={index} className="flex items-center justify-between mb-6">
                <div className="flex items-end">
                  <Icon icon={token.icon} className="h-10 w-10 mr-2" />
                  <div className="flex items-start flex-col">
                    <div className="flex items-center text-base">{toRealSymbol(token.symbol)}</div>
                    <span className="text-xs text-gray-400" title={token.id}>{`${token.id.substring(0, 24)}${
                      token.id.length > 24 ? '...' : ''
                    }`}</span>
                  </div>
                </div>
                <div
                  className="flex flex-col items-end justify-center text-sm"
                  title={toReadableNumber(token.decimals, pool.supplies[token.id])}
                >
                  <div>
                    {Number(toReadableNumber(token.decimals, pool.supplies[token.id])) < 0.01 &&
                    Number(toReadableNumber(token.decimals, pool.supplies[token.id])) > 0
                      ? '< 0.01'
                      : toInternationalCurrencySystem(toReadableNumber(token.decimals, pool.supplies[token.id]))}
                  </div>
                  {Number(toReadableNumber(token.decimals, deposits[token.id])) > 0 && (
                    <div className="text-gray-400">
                      <FormattedMessage id="your_deposit" defaultMessage="Your Deposit:" />
                      {toInternationalCurrencySystem(toReadableNumber(token.decimals, deposits[token.id]))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="border-b border-solid border-hoverGray" />
        <div className="text-sm text-gray-400 pt-4 mx-4">
          {/* fee */}
          <div className="flex items-center justify-between py-2">
            <div>
              <FormattedMessage id="fee" defaultMessage="Fee" />
            </div>
            <div className="text-sm px-2 text-white bg-primary rounded-sm">{`${calculateFeePercent(
              pool.fee,
            )}%`}</div>
          </div>
          {/* TVL */}
          <div className="flex items-center justify-between py-2">
            <div>
              <FormattedMessage id="tvl" defaultMessage="TVL" />
            </div>
            <div
              className="text-base"
              title={toPrecision(scientificNotationToString(poolTVL?.toString() || '0'), 0)}
            >
              {' '}
              ${toInternationalCurrencySystem(poolTVL?.toString())}
            </div>
          </div>
          <div className="flex items-center justify-between py-2">
            <div>
              <FormattedMessage id="h24_volume" defaultMessage="24h volume" />
            </div>
            <div>{dayVolume ? toInternationalCurrencySystem(dayVolume) : '-'}</div>
          </div>
          <div className="flex items-center justify-between py-2">
            <div>
              <FormattedMessage id="total_shares" defaultMessage="Total Shares" />
            </div>
            <div>{toInternationalCurrencySystem(toReadableNumber(24, pool?.shareSupply))}</div>
          </div>
          <div className="flex items-center justify-between pt-2 pb-2">
            <div>
              <FormattedMessage id="my_shares" defaultMessage="My Shares" />
            </div>
            <div>
              <MyShares shares={shares} totalShares={pool.shareSupply} poolId={pool.id} stakeList={stakeList} />
            </div>
          </div>
        </div>
        {isSignedIn ? (
          <div className="flex items-center justify-around pb-10 pt-3">
            <div className="pr-2">
              <SolidButton
                padding="px-3"
                className="w-48 h-10 xs:w-full  md:w-full xs:col-span-1 md:col-span-1 md:text-sm xs:text-sm"
                onClick={() => {
                  setShowFunding(true)
                }}
              >
                <FormattedMessage id="add_liquidity" defaultMessage="Add Liquidity" />
              </SolidButton>
            </div>
            <div className="pl-2">
              <OutlineButton
                padding="px-4"
                onClick={() => {
                  setShowWithdraw(true)
                }}
                className="w-48 h-10 xs:w-full md:w-full xs:col-span-1 md:col-span-1 md:text-sm xs:text-sm"
              >
                <FormattedMessage id="remove_liquidity" defaultMessage="Remove Liquidity" />
              </OutlineButton>
            </div>
          </div>
        ) : (
          <div className="mx-4 pb-10 pt-3">
            <ConnectToNearBtn />
          </div>
        )}
      </Card>
      <RemoveLiquidityModal
        pool={pool}
        shares={shares}
        tokens={tokens}
        isOpen={showWithdraw}
        onRequestClose={() => setShowWithdraw(false)}
        style={{
          overlay: {
            backdropFilter: 'blur(15px)',
            WebkitBackdropFilter: 'blur(15px)',
          },
          content: {
            outline: 'none',
            position: 'fixed',
            bottom: '50%',
          },
        }}
      />
      <AddLiquidityModal
        pool={pool}
        tokens={tokens}
        isOpen={showFunding}
        onRequestClose={() => setShowFunding(false)}
        overlayClassName=""
        style={{
          overlay: {
            backdropFilter: 'blur(4px)',
            WebkitBackdropFilter: 'blur(4px)',
          },
          content: {
            outline: 'none',
            position: 'fixed',
            bottom: '50%',
          },
        }}
      />
    </>
  )
}

export default AddLiquidityCard
