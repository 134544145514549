import React, { useEffect, useState } from 'react'
import { Card } from '~components/card/Card'
import { getLendingPools } from '~services/lending'
import { FormattedMessage } from 'react-intl'
import { toReadableNumber, toRoundedReadableNumber } from '~utils/numbers'
import { useHistory } from 'react-router-dom'
import { SolidButton } from '~components/button/Button'
import { useToken } from '~state/token'

interface GetLendingPoolsOptions {
  pool_id: number
  owner_id: string
  lend_token_id: string
  collateral_token_id: string
  min_cr: number
  max_utilization: number
  min_lend_token_deposit: string
  min_lend_token_borrow: string
  total_lend_asset_deposit: string
  total_collateral_deposit: string
  total_collateral_deposit_value_usd: string
  total_borrow: string
  total_borrow_value_usd: string
  fixed_interest_rate: number
  acc_interest_per_share: string
  last_acc_interest_update_timestamp_sec: number
  liquidation_bonus: number
  token_meta_info: {
    lend_token_price: {
      multiplier: string
      decimals: number
    }
    collateral_token_price: {
      multiplier: string
      decimals: number
    }
    lend_token_info: {
      token_id: string
      decimals: number
    }
    collateral_token_info: {
      token_id: string
      decimals: number
    }
  }
}

function Icon(props: { icon?: string; className?: string; style?: any }) {
  const { icon, className, style } = props
  return icon ? (
    <img className={`block ${className} rounded-full`} src={icon} style={style} alt="" />
  ) : (
    <div className={`rounded-full ${className} border border-primary border-solid`} style={style} />
  )
}

function LendingCard({ pool }: { pool: GetLendingPoolsOptions }) {
  const history = useHistory()
  const lend_token = useToken(pool?.lend_token_id)

  const _minCR = pool.min_cr / 100
  const _maxUtilization = pool.max_utilization / 100
  const _currentUtilization = toRoundedReadableNumber({
    decimals: 2,
    number: (
      (Number(pool.total_borrow) /
        Number(pool.total_lend_asset_deposit === '0' ? '1' : pool.total_lend_asset_deposit)) *
      100
    ).toString(),
    precision: 2,
  })

  const _interestRate = pool.fixed_interest_rate / 100
  const _liquidationBonus = pool.liquidation_bonus / 100
  const _liquidityLendAssetDeposit = toReadableNumber(
    pool.token_meta_info.lend_token_info.decimals,
    pool.total_lend_asset_deposit,
  )
  const _liquidityCollateralDeposit = toReadableNumber(
    pool.token_meta_info.collateral_token_info.decimals,
    pool.total_collateral_deposit,
  )

  return (
    <Card
      key={pool.pool_id}
      width="w-full"
      padding="px-4 py-8"
      bgcolor="bg-secondary"
      className="text-primaryText outline-none shadow-4xl"
    >
      <div className="flex items-center justify-between mb-8 mx-2">
        <div>
          <div className="inline-block my-1">
            <span className="font-extralight text-gray-400">Creator:&nbsp;</span>
            <span className="font-medium">{pool.owner_id}</span>
          </div>
          <div className="inline-block my-1">
            <span className="font-extralight text-gray-400">Lending Token:&nbsp;</span>
            <span className="font-medium">{pool.lend_token_id}</span>
          </div>
          <div className="inline-block my-1">
            <span className="font-extralight text-gray-400">Collateral Token:&nbsp;</span>
            <span className="font-medium">{pool.collateral_token_id}</span>
          </div>
        </div>
        <Icon icon={lend_token?.icon} className="h-20 w-20" />
      </div>
      <div className="px-6 py-6 rounded-2xl bg-gray-100 text-center">
        <div className="flex justify-between my-1">
          <span className="font-extralight text-gray-400">Liquidity</span>
          <span className="font-medium">
            {_liquidityLendAssetDeposit} / {_liquidityCollateralDeposit}
          </span>
        </div>
        <div className="flex justify-between my-1">
          <span className="font-extralight text-gray-400">Min CR</span>
          <span className="font-medium">{_minCR}%</span>
        </div>
        <div className="flex justify-between my-1">
          <span className="font-extralight text-gray-400">Max Utilization</span>
          <span className="font-medium">{_maxUtilization}%</span>
        </div>
        <div className="flex justify-between my-1">
          <span className="font-extralight text-gray-400">Current Utilization</span>
          <span className="font-medium">{_currentUtilization}%</span>
        </div>
        <div className="flex justify-between my-1">
          <span className="font-extralight text-gray-400">Interest Rate</span>
          <span className="font-medium">{_interestRate}%</span>
        </div>
        <div className="flex justify-between my-1">
          <span className="font-extralight text-gray-400">Liquidation Bonus</span>
          <span className="font-medium">{_liquidationBonus}%</span>
        </div>
      </div>
      <SolidButton
        className="hover:bg-violet-700 mt-4 px-4 w-full"
        onClick={() => history.push(`/lending/${pool.pool_id}`)}
      >
        View Details
      </SolidButton>
    </Card>
  )
}

function LendingPage() {
  const [pools, setPools] = useState([])

  useEffect(() => {
    getLendingPools().then(setPools)
  }, [])

  console.log('lendingPools', pools)
  return (
    <div className="flex flex-col mx-auto items-center -mt-5 xs:px-4 md:px-4 w-full lg:w-4/5 xl:w-4/6">
      <div className="mb-4 text-xl gray-400">
        <FormattedMessage id="lending_pools" defaultMessage="Lending Pools" />
      </div>
      <div className="w-full">
        <div className="grid gap-6 grid-cols-2 2xl:grid-cols-3 xs:grid-cols-1 md:grid-cols-1">
          {pools?.map((pool, i) => (
            <LendingCard pool={pool} key={i.toString()} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LendingPage
