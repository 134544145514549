import React from 'react'
import { toRealSymbol } from '~utils/token'
import { TokenMetadata } from '~services/ft-contract'
import { MdKeyboardArrowDown } from 'react-icons/md'

export default function Icon({
  className = '',
  token,
  label = true,
  size = 6,
  showArrow = true,
  hover,
}: {
  className?: string
  token: TokenMetadata
  label?: boolean
  size?: number | string
  showArrow?: boolean
  hover?: boolean
}) {
  return (
    <div
      className={`flex items-center text-lg rounded px-2 py-2.5 hover:bg-cardBg ${hover ? 'cursor-pointer' : ''}`}
      style={{ lineHeight: 'unset' }}
    >
      <img key={token.id} className="mr-1.5 h-6 w-6 border rounded-full border-secondary" src={token.icon} alt="" />
      {label && <p className="block text-sm">{toRealSymbol(token.symbol)}</p>}
      {showArrow && (
        <div className="pl-1.5 xs:pl-1 text-xs">
          <MdKeyboardArrowDown size={16} />
        </div>
      )}
    </div>
  )
}

export function IconLeft({
  className = '',
  token,
  label = true,
  size = 6,
  showArrow = true,
  hover,
}: {
  className?: string
  token: TokenMetadata
  label?: boolean
  size?: number | string
  showArrow?: boolean
  hover?: boolean
}) {
  return (
    <div
      className={`flex items-center text-lg rounded-full flex-shrink-0 ${className} ${
        hover ? 'pr-4 bg-black bg-opacity-20 cursor-pointer' : ''
      }`}
      style={{ lineHeight: 'unset' }}
    >
      <img
        key={token.id}
        className="mr-2 xs:ml-0 xs:mr-1 xs:relative xs:right-1 h-11 w-11 xs:h-7 xs:w-7 border rounded-full border-primary"
        src={token.icon}
        alt=""
      />
      {label && <p className="block text-lg xs:text-sm">{toRealSymbol(token.symbol)}</p>}
      {showArrow && (
        <div className="pl-2 xs:pl-1 text-xs">
          <MdKeyboardArrowDown size={18} className={`${hover ? 'text-primary' : ''}`} />
        </div>
      )}
    </div>
  )
}
