import React, { useRef, useState } from 'react'

interface InputEmailProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChangeEmail?: (email: string) => void
}

export default function InputEmail({ onChangeEmail, className, disabled = false, ...rest }: InputEmailProps) {
  const ref = useRef<HTMLInputElement>()
  const field = useRef<HTMLFieldSetElement>()
  const [symbolsArr] = useState(['+', '-'])

  const [isFocus, setIsFocus] = useState<boolean>(false)

  const handleChange = (email: string) => {
    if (onChangeEmail) onChangeEmail(email)

    ref.current.value = email
  }

  return (
    <>
      <fieldset
        className={`${className} ${isFocus ? ' border border-primary rounded' : ' border border-transparent rounded'}`}
        ref={field}
      >
        <div className={`relative flex align-center items-center bg-inputDarkBg rounded`}>
          <input
            ref={ref}
            onWheel={() => ref.current.blur()}
            {...rest}
            step="any"
            className={`xs:text-sm text-lg w-full p-2 pl-3 ${disabled ? 'text-gray-200 placeholder-gray-200' : ''}`}
            type="email"
            placeholder="Your email address"
            onChange={({ target }) => handleChange(target.value)}
            disabled={disabled}
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
            onFocus={() => {
              setIsFocus(true)
            }}
            onBlur={() => {
              setIsFocus(false)
            }}
          />
        </div>
      </fieldset>
    </>
  )
}
