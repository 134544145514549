import { useContext, useEffect, useState } from 'react'
import { getVeMetadata, getVeMetadataForAccount } from '~services/venst'
// import { TokenMetadata } from '~services/ft-contract'
import { getCurrentWallet, WalletContext } from "~utils/sender-wallet"

interface VeMetadataOptions {
  min_days: number
  max_days: number
  max_time: number
  max_withdrawal_penalty: number
  precision: number
  locked_token: string
  penalty_collector: string
  min_locked_amount: string
  early_withdraw_penalty_rate: number
}

export const useVeMetada = () => {
  const [metadata, setMetadata] = useState<VeMetadataOptions>()
  useEffect(() => {
    getVeMetadata().then(setMetadata)
  }, [])
  return metadata
}

export const useVeMetadataForAccount = () => {
  const [metadata, setMetadata] = useState([])
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  const { wallet, wallet_type } = getCurrentWallet()

  useEffect(() => {
    if (isSignedIn && wallet.account) {
      getVeMetadataForAccount().then(setMetadata)
    } else {
      setMetadata([])
    }
  }, [isSignedIn, wallet_type, wallet.account])
  return metadata
}
