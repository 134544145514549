import getConfig from './config'
import {
  near,
  Transaction,
  executeMultipleTransactions,
  ONE_YOCTO_NEAR,
  NnStableFunctionCallOptions,
  LP_STORAGE_AMOUNT,
  STABLE_POOL_ID,
  N_STABLE_CONTRACT_ID,
} from './near'
import { TokenMetadata } from './ft-contract'

import { toNonDivisibleNumber, toReadableNumber } from '~utils/numbers'
import { getDepositTransactions, getTokenBalance } from '~services/token'
import { ExplorerType, getExplorer } from '~utils/device'
import { registerTokensAction } from '~services/creators/token'
const { networkId } = getConfig()
const explorerType = getExplorer()

interface AddStableLiquidityOptions {
  id: number
  amounts: string[]
  min_shares: string
  tokens: TokenMetadata[]
}

export const addLiquidityToStablePool = async ({ id, amounts, min_shares, tokens }: AddStableLiquidityOptions) => {
  const depositTransactions = await getDepositTransactions({
    tokens,
    amounts: amounts.map((amount, i) => toReadableNumber(tokens[i].decimals, amount)),
  })

  const actions: NnStableFunctionCallOptions[] = [
    {
      methodName: 'add_stable_liquidity',
      args: { pool_id: id, amounts, min_shares },
      amount: LP_STORAGE_AMOUNT,
    },
  ]

  const allTokenIds = getConfig().STABLE_TOKEN_IDS

  const balances = await Promise.all(allTokenIds.map((tokenId) => getTokenBalance(tokenId)))
  let notRegisteredTokens: string[] = []
  for (let i = 0; i < balances.length; i++) {
    if (Number(balances[i]) === 0) {
      notRegisteredTokens.push(allTokenIds[i])
    }
  }

  if (notRegisteredTokens.length > 0 && explorerType !== ExplorerType.Firefox) {
    actions.unshift(registerTokensAction(notRegisteredTokens))
  }

  const transactions: Transaction[] = depositTransactions

  if (notRegisteredTokens.length > 0 && explorerType === ExplorerType.Firefox) {
    transactions.push({
      receiverId: N_STABLE_CONTRACT_ID,
      functionCalls: [registerTokensAction(notRegisteredTokens)],
    })
  }

  transactions.push({
    receiverId: N_STABLE_CONTRACT_ID,
    functionCalls: [...actions],
  })

  return executeMultipleTransactions(transactions)
}

export const fetchMultiplier = async () => {
  try {
    const response = await near.connection.provider.query({
      request_type: 'call_function',
      account_id: `priceoracle.${networkId === 'mainnet' ? 'near' : 'testnet'}`,
      method_name: 'get_price_data',
      args_base64: btoa(`{"asset_ids": ["wrap.${networkId === 'mainnet' ? 'near' : 'testnet'}"]}`),
      finality: 'final',
    })

    const res = JSON.parse(
      //@ts-ignore
      response.result.map((x: any) => String.fromCharCode(x)).join(''),
    )

    return res.prices[0].price
  } catch (error) {
    console.warn('Failed to load ', error)
  }
}
