import React, { useContext, useState, useEffect } from 'react'
import { matchPath } from 'react-router'
import { Context } from '~components/wrapper'
import getConfig from '~services/config'
import { Logo, Near, MobileLogo } from '~components/icon'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Card } from '~components/card/Card'

import { FormattedMessage } from 'react-intl'
import { FiChevronDown, FiExternalLink } from 'react-icons/fi'
import { IoChevronBack } from 'react-icons/io5'
import { MdExpandMore, MdMoreHoriz } from 'react-icons/md'
import { FiChevronRight } from 'react-icons/fi'
import { useMenuItems } from '~utils/menu'
import { MobileNavBar } from './MobileNav'
import { getCurrentWallet } from '~utils/sender-wallet'
import { WalletSelectorModal } from './WalletSelector'
import { WalletContext } from '~utils/sender-wallet'
import { getAccountName } from '~utils/sender-wallet'
import { ftGetTokensMetadata } from '~services/ft-contract'
import { useTokenBalances } from '~state/token'
import { toReadableNumber } from '~utils/numbers'

import { N_STABLE_SWAP_SWAPPAGE_TAB_KEY } from '~pages/SwapPage'

const config = getConfig()

function Anchor({ to, pattern, name, className }: { to: string; pattern: string; name: string; className?: string }) {
  const [hover, setHover] = useState(false)
  const location = useLocation()
  const isSelected = matchPath(location.pathname, {
    path: pattern,
    exact: true,
    strict: false,
  })

  return (
    <Link
      to={to}
      className="relative"
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <h2
        className={`link hover:text-primary text-lg font-medium lg:px-4 px-2.5 py-4 cursor-pointer relative z-10 ${className} ${
          isSelected ? 'text-primary' : 'text-slate-900'
        }`}
      >
        <FormattedMessage id={name} defaultMessage={name} />
      </h2>
    </Link>
  )
}

function AccountEntry({
  setShowWalletSelector,
  showWalletSelector,
  hasBalanceOnRefAccount,
}: {
  setShowWalletSelector: (show: boolean) => void
  showWalletSelector: boolean
  hasBalanceOnRefAccount: boolean
}) {
  const history = useHistory()
  const [hover, setHover] = useState(false)

  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  const { wallet, wallet_type } = getCurrentWallet()

  const [showAccountTip, setShowAccountTip] = useState<boolean>(false)

  useEffect(() => {
    setShowAccountTip(hasBalanceOnRefAccount)
  }, [hasBalanceOnRefAccount])

  const location = useLocation()

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAccountTip(false)
    }, 4000)

    return () => clearTimeout(timer)
  }, [showAccountTip])

  const accountList = [
    {
      textId: 'view_account',
      selected: location.pathname == '/account',
      click: () => {
        if (location.pathname == '/account') {
          localStorage.setItem(N_STABLE_SWAP_SWAPPAGE_TAB_KEY, 'stable')
          window.location.reload()
        } else {
          history.push('/account')
        }
      },
    },
    {
      textId: 'go_to_near_wallet',
      subIcon: <FiExternalLink />,
      click: () => {
        window.open(config.walletUrl, '_blank')
      },
    },
    {
      textId: 'sign_out',
      click: () => {
        wallet.signOut()
        wallet_type === 'near-wallet' && window.location.assign('/')
      },
    },
  ]

  return (
    <div className="bubble-box relative user text-sm text-center justify-end z-30 mx-3.5">
      <div
        className={`cursor-pointer font-bold items-center justify-end text-center overflow-visible relative`}
        onMouseEnter={() => {
          setShowAccountTip(false)
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <div
          className={`inline-flex px-5 py-2.5 items-center justify-center shadow-4xl bg-primary text-white rounded-md`}
        >
          <div className="pr-1">
            <Near color="white" />
          </div>
          <div className="overflow-ellipsis overflow-hidden whitespace-nowrap account-name">
            {isSignedIn ? (
              <span className="flex ml-1 items-center">
                {getAccountName(wallet.getAccountId())}
                <MdExpandMore size={24} className="ml-1" />
              </span>
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setShowWalletSelector(true)

                  setHover(false)
                }}
                type="button"
              >
                <span className="ml-1 text-xs">
                  <FormattedMessage id="connect_to_near" defaultMessage="Connect to NEAR" />
                </span>
              </button>
            )}
          </div>
        </div>
        {isSignedIn && hover ? (
          <div className={`absolute top-10 pt-2 right-0 w-64 z-20`}>
            <Card className="menu-max-height cursor-default shadow-4xl" width="w-64" padding="py-4" rounded="rounded">
              {accountList.map((item) => {
                return (
                  <div
                    onClick={item.click}
                    key={item.textId}
                    className={`flex items-center text-sm cursor-pointer font-semibold py-3 pl-4 text-primaryText hover:bg-cardBg ${
                      item.selected ? 'bg-cardBg' : 'text-primaryText'
                    }`}
                  >
                    <label className="cursor-pointer">
                      <FormattedMessage id={item.textId} />
                    </label>
                    {item.subIcon ? <label className="text-lg ml-2">{item.subIcon}</label> : null}
                  </div>
                )
              })}
            </Card>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function BorrowMenu() {
  const location = useLocation()
  const [hover, setHover] = useState(false)
  const history = useHistory()

  const links = [
    {
      label: <FormattedMessage id="borrow" defaultMessage="borrow" />,
      path: '/borrow',
    },
    {
      label: <FormattedMessage id="liquidation_history" defaultMessage="Liquidation History" />,
      path: '/borrow/history',
    },
  ]

  return (
    <div className="relative" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={`flex items-center justify-center ${ hover ? 'text-primary' : ''}`}>
        <div className="relative">
          <h2 className={`flex items-center link text-lg font-medium p-4 cursor-pointer`}>
            <FormattedMessage id="borrow" defaultMessage="borrow" />
            <label className="text-xl ml-1">
              <FiChevronDown />
            </label>
          </h2>
        </div>
      </div>
      <div
        className={`${hover ? 'block' : 'hidden'} absolute top-12 -left-20 rounded-md`}
        style={{
          zIndex: 999,
        }}
      >
        <Card width="w-64" padding="py-4" rounded="rounded-md" className="border shadow-4xl z-40">
          {links.map((link) => {
            return (
              <div
                key={link.path}
                className={`flex justify-start items-center hover:bg-navHighLightBg text-sm font-semibold z-40  hover:text-primary cursor-pointer py-4 pl-7 ${
                  hover ? 'text-primary bg-navHighLightBg' : 'text-primaryText'
                }`}
                onClick={() => history.push(link.path)}
              >
                {link.label}
              </div>
            )
          })}
        </Card>
      </div>
    </div>
  )
}

function MoreMenu() {
  const [hover, setHover] = useState(false)
  const [parentLabel, setParentLabel] = useState('')
  const { menuData } = useMenuItems()
  const [curMenuItems, setCurMenuItems] = useState(menuData)
  const context = useContext(Context)
  const location = useLocation()
  const history = useHistory()
  const onClickMenuItem = (items: any[], label: string) => {
    setCurMenuItems(items)
    setParentLabel(label)
  }
  const switchLanuage = (label: string) => {
    context.selectLanguage(label)
  }
  const handleMoreMenuClick = (url: string, isExternal: boolean, label: string, children?: any, language?: string) => {
    if (url) {
      if (isExternal) {
        window.open(url)
      } else {
        history.push(url)
      }
    } else if (children) {
      onClickMenuItem?.(children, label)
    } else {
      switchLanuage(language)
    }
  }
  const hasSubMenu = curMenuItems.some(({ children }) => !!children?.length)
  return (
    <div
      className="relative z-30"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false)
        onClickMenuItem?.(menuData, '')
      }}
    >
      <div
        className="text-primaryText hover:text-primary cursor-pointer px-2 py-2 border rounded"
        style={{ borderColor: '#B1B1B1' }}
      >
        <MdMoreHoriz size={24} fill="#8F8F8F" />
      </div>
      <div className={`${hover ? 'block' : 'hidden'} absolute top-10 pt-2 right-0 rounded-md`}>
        <Card width="w-64" padding="py-4" rounded="rounded-md" className="shadow-4xl shadow-4xl">
          {!hasSubMenu && parentLabel && (
            <div
              className="whitespace-nowrap hover:text-white text-left items-center flex justify-start text-sm font-semibold text-primaryText cursor-pointer py-4 pl-4"
              onClick={() => onClickMenuItem?.(menuData, '')}
            >
              <IoChevronBack className="text-xl " />
              <span className=" ml-8">{parentLabel}</span>
            </div>
          )}
          {curMenuItems.map(({ id, url, children, label, icon, logo, isExternal, language }) => {
            const isSelected =
              url &&
              !isExternal &&
              matchPath(location.pathname, {
                path: url,
                exact: true,
                strict: false,
              })

            return (
              <div
                key={id}
                className={`whitespace-nowrap ${
                  id === 0 ? 'lg:flex lg2:hidden' : ''
                } text-left items-center flex justify-start hover:bg-cardBg text-primaryText text-sm font-semibold
                 ${isSelected ? 'bg-cardBg' : ''}
                 cursor-pointer py-3 pl-4 ${parentLabel ? 'pl-14' : ''}`}
                onClick={() => handleMoreMenuClick(url, isExternal, label, children, language)}
              >
                {logo && <span className={`text-xl w-8 text-left flex justify-center mr-2`}>{logo}</span>}
                {label}
                <span className="ml-4 text-xl">{icon}</span>
                {children && (
                  <span className="text-xl absolute right-4">
                    <FiChevronRight />
                  </span>
                )}
              </div>
            )
          })}
        </Card>
      </div>
    </div>
  )
}

function NavigationBar() {
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn
  const [showWalletSelector, setShowWalletSelector] = useState(false)

  const [tokensMeta, setTokensMeta] = useState<{}>()

  const [pathnameState, setPathnameState] = useState<boolean>(window.location.pathname !== '/account')
  const setPatheState = () => setPathnameState(window.location.pathname !== '/account')

  useEffect(() => {
    const _historyWrap = function (type: any) {
      const orig = history[type]
      const e = new Event(type)
      return function () {
        const rv = orig.apply(this, arguments)
        //@ts-ignore
        e.arguments = arguments
        window.dispatchEvent(e)
        return rv
      }
    }
    history.pushState = _historyWrap('pushState')
    history.replaceState = _historyWrap('replaceState')
    window.addEventListener('popstate', (e) => {
      setPatheState()
    })
    window.addEventListener('pushState', function (e) {
      setPatheState()
    })
    window.addEventListener('replaceState', function (e) {
      setPatheState()
    })
  }, [])

  const [hasBalanceOnRefAccount, setHasBalanceOnRefAccount] = useState<boolean>(false)

  const refAccountBalances = useTokenBalances()

  useEffect(() => {
    if (!refAccountBalances) return

    const ids = Object.keys(refAccountBalances)

    ftGetTokensMetadata(ids).then(setTokensMeta)
  }, [refAccountBalances, isSignedIn])

  useEffect(() => {
    if (!refAccountBalances || !tokensMeta) {
      setHasBalanceOnRefAccount(false)
      return
    }
    const hasRefBalanceOver = Object.entries(refAccountBalances).some(([id, balance]) => {
      return Number(toReadableNumber(tokensMeta?.[id]?.decimals || 24, balance) || '0') > 0.001
    })

    setHasBalanceOnRefAccount(hasRefBalanceOver)
  }, [refAccountBalances, tokensMeta, isSignedIn])

  return (
    <>
      <div className="nav-wrap text-center relative bg-secondary">
        <nav className="flex items-center justify-between px-3 lg:px-9 py-3 col-span-8 ">
          <div className="relative -top-0.5 flex-1">
            <span className="lg:hidden md:show">
              <MobileLogo />
            </span>
            <span className="lg:show md:hidden xs:hidden">
              <Logo />
            </span>
          </div>
          <div className="flex items-center md:hidden xs:hidden lg:show">
            <Anchor to="/" pattern="/" name="swap" />
            <BorrowMenu />
            <Anchor to="/lending" pattern="/lending" name="lending" />
            <Anchor to="/option-trading" pattern="/option-trading" name="option_trading" />
            <Anchor to="/venst" pattern="/venst" name="veNST" />
            {/*<PoolsMenu />*/}
          </div>
          <div className="flex items-center justify-end flex-1 md:hidden xs:hidden lg:show">
            {/*<>*/}
            {/*  <div*/}
            {/*    className="mr-3"*/}
            {/*    onClick={() => {*/}
            {/*      setShowUSN(true);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <USNBuyComponent />*/}
            {/*  </div>*/}
            {/*  <USNPage*/}
            {/*    isOpen={showUSN}*/}
            {/*    onRequestClose={() => {*/}
            {/*      setShowUSN(false);*/}
            {/*    }}*/}
            {/*    style={{*/}
            {/*      overlay: {*/}
            {/*        backdropFilter: 'blur(15px)',*/}
            {/*        WebkitBackdropFilter: 'blur(15px)',*/}
            {/*      },*/}
            {/*      content: {*/}
            {/*        outline: 'none',*/}
            {/*        position: 'fixed',*/}
            {/*        width: 550,*/}
            {/*        bottom: '50%',*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  ></USNPage>*/}
            {/*</>*/}
            <AccountEntry
              hasBalanceOnRefAccount={hasBalanceOnRefAccount}
              setShowWalletSelector={setShowWalletSelector}
              showWalletSelector={showWalletSelector}
            />
            <div className={isSignedIn ? ' relative right-3 flex items-center' : 'hidden'}></div>
            <MoreMenu />
          </div>
          <MobileNavBar
            hasBalanceOnRefAccount={hasBalanceOnRefAccount}
            isSignedIn={isSignedIn}
            setShowWalletSelector={setShowWalletSelector}
            showWalletSelector={showWalletSelector}
          />
        </nav>
      </div>
      <WalletSelectorModal
        setShowWalletSelector={setShowWalletSelector}
        isOpen={showWalletSelector}
        onRequestClose={() => {
          window.location.reload()
          setShowWalletSelector(false)
        }}
      />
    </>
  )
}
export default NavigationBar
