import { Near, keyStores, utils, WalletConnection } from 'near-api-js'
import { functionCall } from 'near-api-js/lib/transaction'
import BN from 'bn.js'
import getConfig from './config'
import SpecialWallet from './SpecialWallet'
import { getCurrentWallet, senderWallet } from '~utils/sender-wallet'
import { SENDER_WALLET_SIGNEDIN_STATE_KEY, WALLET_TYPE } from '~utils/sender-wallet'
import { VENST_CONTRACT_ID } from "~services/venst"

const config = getConfig()

export const N_STABLE_CONTRACT_ID = config.N_STABLE_CONTRACT_ID

export const NAI_CONTRACT_ID = config.NAI_CONTRACT_ID

export const LENDING_CONTRACT_ID = config.LENDING_CONTRACT_ID

export const POOL_TOKEN_REFRESH_INTERVAL = config.POOL_TOKEN_REFRESH_INTERVAL

export const STABLE_TOKEN_IDS = config.STABLE_TOKEN_IDS

export const STABLE_POOL_ID = config.STABLE_POOL_ID

export const STABLE_POOL_USN_ID = config.STABLE_POOL_USN_ID

export const STABLE_TOKEN_USN_IDS = config.STABLE_TOKEN_USN_IDS

export const isStableToken = (id: string) => {
  return STABLE_TOKEN_IDS.includes(id) || STABLE_TOKEN_USN_IDS.includes(id)
}

export const BLACKLIST_POOL_IDS = config.BLACKLIST_POOL_IDS

export const STABLE_TOKEN_INDEX = config.STABLE_TOKEN_INDEX

export const STABLE_TOKEN_USN_INDEX = config.STABLE_TOKEN_USN_INDEX

export const getStableTokenIndex = (stable_pool_id: string | number) => {
  return Number(stable_pool_id) === Number(STABLE_POOL_ID)
    ? STABLE_TOKEN_INDEX
    : STABLE_TOKEN_USN_INDEX
}

export const isStablePool = (id: string | number) => {
  return Number(id) === STABLE_POOL_ID || Number(id) === STABLE_POOL_USN_ID
}

export const ALL_STABLE_POOL_IDS = [
  STABLE_POOL_ID,
  STABLE_POOL_USN_ID,
]
  .filter((_) => _)
  .map((id) => id.toString())
export const REF_FARM_CONTRACT_ID = config.REF_FARM_CONTRACT_ID

export const LP_STORAGE_AMOUNT = '0.01'
export const LP_MORE_STORAGE_AMOUNT = '0.02876'

export const ONE_YOCTO_NEAR = '0.000000000000000000000001'

export const keyStore = new keyStores.BrowserLocalStorageKeyStore()
//@ts-ignore
keyStore?.reKey = () => {}

export const near = new Near({
  keyStore,
  headers: {},
  ...config,
})
export const wallet = new SpecialWallet(near, REF_FARM_CONTRACT_ID)

export const getGas = (gas: string) => (gas ? new BN(gas) : new BN('100000000000000'))
export const getAmount = (amount: string) => (amount ? new BN(utils.format.parseNearAmount(amount)) : new BN('0'))

export interface NnStableViewFunctionOptions {
  methodName: string
  args?: object
}

export interface NnStableFunctionCallOptions extends NnStableViewFunctionOptions {
  gas?: string
  amount?: string
}

export const nStableFunctionCall = ({ methodName, args, gas, amount }: NnStableFunctionCallOptions) => {
  return wallet.account().functionCall(N_STABLE_CONTRACT_ID, methodName, args, getGas(gas), getAmount(amount))
}

export const nStableViewFunction = ({ methodName, args }: NnStableViewFunctionOptions) => {
  return wallet.account().viewFunction(N_STABLE_CONTRACT_ID, methodName, args)
}

export const naiViewFunction = ({ methodName, args }: NnStableViewFunctionOptions) => {
  return wallet.account().viewFunction(NAI_CONTRACT_ID, methodName, args)
}

export const veNSTViewFunction = ({ methodName, args }: NnStableViewFunctionOptions) => {
  return wallet.account().viewFunction(VENST_CONTRACT_ID, methodName, args)
}

export const lendingViewFunction = ({ methodName, args }: NnStableViewFunctionOptions) => {
  return wallet.account().viewFunction(LENDING_CONTRACT_ID, methodName, args)
}

export const nStableManyFunctionCalls = (functionCalls: NnStableFunctionCallOptions[]) => {
  const actions = functionCalls.map((fc) => functionCall(fc.methodName, fc.args, getGas(fc.gas), getAmount(fc.amount)))
  const { wallet, wallet_type } = getCurrentWallet()

  return wallet_type === WALLET_TYPE.SENDER_WALLET
    ? wallet.sendTransactionWithActions(N_STABLE_CONTRACT_ID, functionCalls)
    : wallet.account().sendTransactionWithActions(N_STABLE_CONTRACT_ID, actions)
}

export interface Transaction {
  receiverId: string
  functionCalls: NnStableFunctionCallOptions[]
}

export const executeMultipleTransactions = async (transactions: Transaction[], callbackUrl?: string) => {
  const { wallet, wallet_type } = getCurrentWallet()
  const currentTransactions =
    wallet_type === WALLET_TYPE.SENDER_WALLET || wallet_type == WALLET_TYPE.NEARFI_WALLET
      ? transactions
      : await Promise.all(
          transactions.map((t, i) => {
            return wallet.createTransaction({
              receiverId: t.receiverId,
              nonceOffset: i + 1,
              actions: t.functionCalls.map((fc) =>
                functionCall(fc.methodName, fc.args, getGas(fc.gas), getAmount(fc.amount)),
              ),
            })
          }),
        )

  return wallet.requestSignTransactions(currentTransactions, callbackUrl)
}

export const refFarmFunctionCall = ({ methodName, args, gas, amount }: NnStableFunctionCallOptions) => {
  const { wallet, wallet_type } = getCurrentWallet()

  return wallet.account().functionCall(REF_FARM_CONTRACT_ID, methodName, args, getGas(gas), getAmount(amount))
}

export const refFarmViewFunction = ({ methodName, args }: NnStableViewFunctionOptions) => {
  return wallet.account().viewFunction(REF_FARM_CONTRACT_ID, methodName, args)
}

export const refFarmManyFunctionCalls = (functionCalls: NnStableFunctionCallOptions[]) => {
  const actions = functionCalls.map((fc) => functionCall(fc.methodName, fc.args, getGas(fc.gas), getAmount(fc.amount)))
  const { wallet, wallet_type } = getCurrentWallet()

  return wallet_type === WALLET_TYPE.SENDER_WALLET
    ? wallet.sendTransactionWithActions(REF_FARM_CONTRACT_ID, functionCalls)
    : wallet.account().sendTransactionWithActions(REF_FARM_CONTRACT_ID, actions)
}

export const executeFarmMultipleTransactions = async (transactions: Transaction[], callbackUrl?: string) => {
  const { wallet, wallet_type } = getCurrentWallet()

  const currentTransactions =
    wallet_type === WALLET_TYPE.SENDER_WALLET
      ? transactions
      : await Promise.all(
          transactions.map((t, i) => {
            return wallet.createTransaction({
              receiverId: t.receiverId,
              nonceOffset: i + 1,
              actions: t.functionCalls.map((fc) =>
                functionCall(fc.methodName, fc.args, getGas(fc.gas), getAmount(fc.amount)),
              ),
            })
          }),
        )

  return wallet.requestSignTransactions(currentTransactions, callbackUrl)
}
