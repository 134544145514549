import React, { useState } from 'react'
import { MdArrowDownward } from 'react-icons/md'

export function ArrowDownGreen() {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 6L9 1" stroke="#00C08B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export function ArrowDownWhite() {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 6L9 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export function BackArrowWhite() {
  return (
    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1L2 9.5L10 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
export function BackArrowGray() {
  return (
    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 1L2 6.5L7 12" stroke="#7E8A93" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export function DownArrowLight() {
  return (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.11372e-08 7.38265C-3.27892e-05 7.54503 0.0723861 7.70172 0.203402 7.82274L3.40758 10.781C3.70106 11.052 4.20422 11.0746 4.53142 10.8315C4.55284 10.8156 4.57321 10.7987 4.59242 10.781L7.79658 7.82274C8.09007 7.55179 8.06274 7.13509 7.73556 6.89203C7.58966 6.7836 7.40038 6.7236 7.20415 6.72358L5.75927 6.72357C5.48313 6.72357 5.25927 6.49971 5.25927 6.22357V0.997837C5.25927 0.446742 4.71866 0 4.05175 0C3.38485 0 2.84423 0.446742 2.84423 0.997825V6.22356C2.84423 6.49971 2.62037 6.72356 2.34423 6.72356H0.795846C0.360713 6.72356 0.00711989 7.01278 0.000104992 7.37175L1.11372e-08 7.38265Z"
        fill="#00C6A2"
      />
    </svg>
  )
}

export function DownArrowLightMobile() {
  return (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.11372e-08 7.38265C-3.27892e-05 7.54503 0.0723861 7.70172 0.203402 7.82274L3.40758 10.781C3.70106 11.052 4.20422 11.0746 4.53142 10.8315C4.55284 10.8156 4.57321 10.7987 4.59242 10.781L7.79658 7.82274C8.09007 7.55179 8.06274 7.13509 7.73556 6.89203C7.58966 6.7836 7.40038 6.7236 7.20415 6.72358L5.75927 6.72357C5.48313 6.72357 5.25927 6.49971 5.25927 6.22357V0.997837C5.25927 0.446742 4.71866 0 4.05175 0C3.38485 0 2.84423 0.446742 2.84423 0.997825V6.22356C2.84423 6.49971 2.62037 6.72356 2.34423 6.72356H0.795846C0.360713 6.72356 0.00711989 7.01278 0.000104992 7.37175L1.11372e-08 7.38265Z"
        fill="#c4c4c4"
      />
    </svg>
  )
}

export function UpArrowLight() {
  return (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.61735C8.00003 3.45497 7.92761 3.29828 7.7966 3.17726L4.59242 0.219006C4.29894 -0.0519651 3.79578 -0.0745939 3.46859 0.168471C3.44716 0.184392 3.42679 0.201262 3.40758 0.219006L0.203419 3.17725C-0.0900672 3.44821 -0.0627426 3.86491 0.26444 4.10797C0.410341 4.2164 0.599622 4.2764 0.795848 4.27642L2.24073 4.27643C2.51687 4.27643 2.74073 4.50029 2.74073 4.77643L2.74073 10.0022C2.74073 10.5533 3.28134 11 3.94825 11C4.61515 11 5.15577 10.5533 5.15577 10.0022L5.15577 4.77644C5.15577 4.50029 5.37963 4.27644 5.65577 4.27644L7.20415 4.27644C7.63929 4.27644 7.99288 3.98722 7.9999 3.62825L8 3.61735Z"
        fill="#00C6A2"
      />
    </svg>
  )
}

export function UpArrowDeep() {
  return (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.61735C8.00003 3.45497 7.92761 3.29828 7.7966 3.17726L4.59242 0.219006C4.29894 -0.0519651 3.79578 -0.0745939 3.46859 0.168471C3.44716 0.184392 3.42679 0.201262 3.40758 0.219006L0.203419 3.17725C-0.0900672 3.44821 -0.0627426 3.86491 0.26444 4.10797C0.410341 4.2164 0.599622 4.2764 0.795848 4.27642L2.24073 4.27643C2.51687 4.27643 2.74073 4.50029 2.74073 4.77643L2.74073 10.0022C2.74073 10.5533 3.28134 11 3.94825 11C4.61515 11 5.15577 10.5533 5.15577 10.0022L5.15577 4.77644C5.15577 4.50029 5.37963 4.27644 5.65577 4.27644L7.20415 4.27644C7.63929 4.27644 7.99288 3.98722 7.9999 3.62825L8 3.61735Z"
        fill="#7E8A93"
      />
    </svg>
  )
}

export function SwapExchange({ onChange }: { onChange: (e?: any) => void }) {
  const [hover, setHover] = useState<boolean>(false)
  return (
    <div
      className="relative flex items-center justify-center -mt-6 mb-4 w-8 h-8 rounded-full cursor-pointer bg-white border"
      onClick={() => {
        onChange()
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center">
        <MdArrowDownward className={`text-primary ${hover ? 'rotate-180' : ''}`} size={18} />
      </div>
    </div>
  )
}
