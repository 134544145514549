import React from 'react'
import { Card } from '~components/card/Card'
import { useParams } from 'react-router'

interface ParamTypes {
  id: string
}

function LendingDetailsPage() {
  const { id } = useParams<ParamTypes>()

  return (
    <div className="flex flex-col mx-auto items-center -mt-5 xs:px-4 md:px-4 w-full lg:w-3/5 xl:w-3/6">
      <Card
        width="w-full"
        padding="px-6 py-8"
        bgcolor="bg-secondary"
        className="text-primaryText outline-none shadow-4xl"
      >
        <h2 className="mt-10 mb-8 text-2xl text-center font-medium">Pool Id: {id}</h2>
      </Card>
    </div>
  )
}

export default LendingDetailsPage
