import React, { useState, useEffect, useContext } from 'react'
import { Card } from '~components/card/Card'
import { TiArrowSortedUp } from 'react-icons/ti'
import { TokenMetadata } from '~services/ft-contract'
import { useTokenBalances, useUserRegisteredTokensAllAndNearBalance } from '~state/token'
import Loading from '../components/layout/Loading'
import { wallet as webWallet } from '../services/near'
import { FormattedMessage } from 'react-intl'
import { toReadableNumber, toInternationalCurrencySystemNature } from '~utils/numbers'
import BigNumber from 'bignumber.js'
import { useHistory } from 'react-router'
import { WalletContext, getCurrentWallet } from '~utils/sender-wallet'

import { getSenderLoginRes } from '~utils/sender-wallet'
import { Near } from '~components/icon'
import { N_STABLE_SWAP_SWAPPAGE_TAB_KEY } from './SwapPage'

const ACCOUNT_PAGE_AURORA_SHOW = N_STABLE_SWAP_SWAPPAGE_TAB_KEY

const accountSortFun = (by: string, currentSort: string, userTokens: TokenMetadata[]) => {
  const sortBy = by || 'near'

  const sortDirection = currentSort.split('-')[1] == 'down' ? 'up' : 'down'

  userTokens.sort((token1: TokenMetadata, token2: TokenMetadata) => {
    const { near: near1, ref: ref1 } = token1
    const { near: near2, ref: ref2 } = token2
    const near1Balance = new BigNumber(near1)
    const ref1Balance = new BigNumber(ref1)

    const near2Balance = new BigNumber(near2)
    const ref2Balance = new BigNumber(ref2)

    const a = sortBy == 'near' ? near1Balance : sortBy === 'ref' ? ref1Balance : ''
    const b = sortBy == 'near' ? near2Balance : sortBy === 'ref' ? ref2Balance : ''

    if (sortDirection == 'down') {
      if (!(a instanceof BigNumber) || a.isGreaterThan(b)) {
        return -1
      } else if (a.isLessThan(b)) {
        return 1
      } else {
        return 0
      }
    } else {
      if (!(a instanceof BigNumber) || a.isLessThan(b)) {
        return -1
      } else if (a.isGreaterThan(b)) {
        return 1
      } else {
        return 0
      }
    }
  })
  return {
    sortUserTokens: userTokens,
    curSort: sortBy + '-' + sortDirection,
  }
}

const getWalletBalance = (item: TokenMetadata) => {
  const { near } = item
  const bigNear = new BigNumber(near)
  if (bigNear.isEqualTo('0')) {
    return '-'
  } else if (bigNear.isLessThan(0.01)) {
    return '<0.01'
  } else {
    return toInternationalCurrencySystemNature(near.toString())
  }
}

function AccountTable(props: any) {
  const {
    userTokens,
    showCrossBalance,
    hasRefBalanceOver,
    hasMapBalanceOver,
    refAccountTokenNumber,
    mapAccountTokenNumber,
  } = props
  const [tokensSort, setTokensSort] = useState(userTokens)
  let [currentSort, setCurrentSort] = useState('')

  console.log('tokensSort', tokensSort)

  useEffect(() => {
    sort(null, 'near')
  }, [hasRefBalanceOver, hasMapBalanceOver])

  const sort = (e?: any, by?: string) => {
    const sortBy = by || e?.currentTarget.dataset.sort || 'near'
    if (currentSort.indexOf(sortBy) == -1 || by) {
      currentSort = sortBy + '-' + 'up'
    }
    console.log('userTokens', userTokens)
    const { sortUserTokens, curSort } = accountSortFun(sortBy, currentSort, userTokens)
    setTokensSort(Array.from(sortUserTokens))
    setCurrentSort(curSort)
  }

  return (
    <table className="w-full text-sm text-gray-400 mt-2 table-auto">
      <thead>
        <tr className="h-9">
          <th className="pl-6 pb-3 text-left">
            <FormattedMessage id="your_portfolio"></FormattedMessage>
          </th>
          <th
            className={`text-right pr-6 pb-3 ${
              (refAccountTokenNumber && !showCrossBalance) || (mapAccountTokenNumber && showCrossBalance)
                ? ''
                : 'pr-10 pb-3'
            }`}
          >
            <span onClick={(e) => sort(e)} data-sort="near" className="flex items-center w-full justify-end ">
              <label className="mx-1 cursor-pointer">Token Balance</label>
              <TiArrowSortedUp
                className={`cursor-pointer ${currentSort == 'near-down' ? 'transform rotate-180' : ''}`}
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {tokensSort.map((item: TokenMetadata & { aurora: string }) => {
          return (
            <tr
              className={`h-16 border-t border-borderColor border-opacity-30 hover:bg-chartBg hover:bg-opacity-20 ${
                new BigNumber(item.near).isEqualTo('0') &&
                ((showCrossBalance && new BigNumber(item.aurora).isEqualTo('0')) ||
                  (!showCrossBalance && new BigNumber(item.ref).isEqualTo('0')))
                  ? 'hidden'
                  : ''
              }`}
              key={item.id}
            >
              <td className="pl-6">
                <div className="flex items-center">
                  <div className="h-10 w-10 rounded-full border mr-2.5 overflow-hidden flex-shrink-0">
                    <img src={item.icon} className="w-full h-full" alt=""></img>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <label className="text-lg text-primaryText">{item.symbol}</label>
                    </div>
                    <label className="text-xs text-primaryText break-all">{item.id}</label>
                  </div>
                </div>
              </td>
              <td width="40%" className={`text-right text-primaryText pr-6 text-base`}>
                <span title={item.near.toString()}>{getWalletBalance(item)}</span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function Account(props: any) {
  const { userTokens } = props

  const [hasRefBalanceOver, setHasRefBalanceOver] = useState<boolean>(false)
  const [hasMapBalanceOver, setHasMapBalanceOver] = useState<boolean>(false)
  const [refAccountTokenNumber, setRefAccountTokenNumber] = useState()
  const [mapAccountTokenNumber, setMapAccountTokenNumber] = useState()

  useEffect(() => {
    const hasRefBalanceOver = userTokens.some((token: TokenMetadata) => {
      return Number(token.ref) > 0.001
    })
    const hasMapBalanceOver = userTokens.some((token: TokenMetadata & { aurora: string }) => {
      return Number(token.aurora) > 0
    })
    const refAccountHasToken = userTokens.filter((token: TokenMetadata) => {
      const { ref } = token
      if (Number(ref) > 0) return true
    })

    const mapAccountHasToken = userTokens.filter((token: TokenMetadata & { aurora: string }) => {
      const { aurora } = token
      return Number(aurora) > 0
    })
    setRefAccountTokenNumber(refAccountHasToken.length)
    setMapAccountTokenNumber(mapAccountHasToken.length)
    setHasRefBalanceOver(hasRefBalanceOver)
    setHasMapBalanceOver(hasMapBalanceOver)
  }, [])

  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  const nearAddress: string = getCurrentWallet().wallet.getAccountId()

  const displayAddrNear = nearAddress.indexOf('.') === -1 ? `${nearAddress.substring(0, 10)}...` : nearAddress

  const tab = new URLSearchParams(window.location.search).get('tab')
  const crossStatus = localStorage.getItem(ACCOUNT_PAGE_AURORA_SHOW)
  const [showCrossBalance, setShowCrossBalance] = useState(
    tab === 'aurora' || (crossStatus === 'cross' && tab !== 'ref'),
  )

  useEffect(() => {
    if (tab === 'ref') {
      localStorage.setItem(ACCOUNT_PAGE_AURORA_SHOW, 'normal')
    }
    window.history.replaceState({}, '', window.location.origin + window.location.pathname)
  }, [tab])

  const accountTitle = !showCrossBalance ? (
    <>
      <label className="text-xl">{isSignedIn && displayAddrNear}</label>
    </>
  ) : (
    <div className="flex items-center">
      <div
        className="rounded-2xl flex items-center text-sm text-white py-0.5 px-3 mr-px"
        style={{
          background: 'rgba(255, 255, 255, 0.15)',
        }}
      >
        <div className="mr-2">
          <Near color="white" />
        </div>

        <div>{displayAddrNear}</div>
      </div>
    </div>
  )

  return (
    <div className="justify-center relative xs:w-11/12 md:w-480px lg:w-480px w-1/2 m-auto mt-16 flex flex-col shadow-4xl rounded-2xl">
      <div className="flex items-center justify-between ">
        <div
          className="relative flex items-center font-semibold bg-white rounded-t-2xl w-full border-b"
          style={{
            height: '66px',
          }}
        >
          <div className="relative ml-7 flex items-center">{accountTitle}</div>
        </div>
      </div>

      <Card className=" w-full px-0 pb-2 pt-2 rounded-tl-none rounded-tr-none">
        <AccountTable
          userTokens={userTokens}
          hasRefBalanceOver={hasRefBalanceOver}
          hasMapBalanceOver={hasMapBalanceOver}
          refAccountTokenNumber={refAccountTokenNumber}
          mapAccountTokenNumber={mapAccountTokenNumber}
          showCrossBalance={showCrossBalance}
        />
      </Card>
    </div>
  )
}

export function AccountPage() {
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn
  const history = useHistory()

  const senderLoginRes = getSenderLoginRes()

  if (!senderLoginRes && !webWallet.isSignedIn()) {
    history.push('/')
    return null
  }

  const userTokens = useUserRegisteredTokensAllAndNearBalance(isSignedIn)
  console.log('userTokens', userTokens)
  const balances = useTokenBalances() // inner account balance
  console.log('balances', balances)

  if (!userTokens || !balances) return <Loading />

  userTokens.forEach((token: TokenMetadata) => {
    const { decimals, id, nearNonVisible, symbol } = token
    token.ref = toReadableNumber(decimals, balances[id] || '0')
    token.near = toReadableNumber(decimals, (nearNonVisible || '0').toString())
  })
  return (
    <>
      <Account userTokens={userTokens} />
    </>
  )
}
