import React, { useEffect, useState } from 'react'
import SwapCard from '../components/swap/SwapCard'
import AddLiquidityCard from '~components/liquidity/AddLiquidityCard'

import Loading from '../components/layout/Loading'
import { FormattedMessage } from 'react-intl'
import { ftGetTokenMetadata, TokenMetadata } from '~services/ft-contract'

export const N_STABLE_SWAP_SWAPPAGE_TAB_KEY = 'N_STABLE_SWAP_SWAPPAGE_TAB_VALUE'

import { Pool, getStablePoolFromCache } from '~services/pool'
import { getCurrentWallet } from '~utils/sender-wallet'
import { getSwapTokenList } from '~services/stable-swap'

const SWAP_MODE_KEY = 'SWAP_MODE_VALUE'

export enum SWAP_MODE {
  STABLE = 'stable',
  LIQUIDITY = 'liquidity',
}

const ChangeSwapMode = ({ swapMode, setSwapMode }: { swapMode: SWAP_MODE; setSwapMode: (e?: any) => void }) => {
  return (
    <div
      className="rounded-2xl bg-cardBg text-primaryText text-lg flex items-center justify-between p-1 w-full mx-auto font-normal"
      style={{
        height: '50px',
      }}
    >
      <span
        className={`py-2 w-1/2 text-center cursor-pointer ${
          swapMode === SWAP_MODE.STABLE ? 'bg-tabChosen rounded-xl' : ''
        }`}
        onClick={() => {
          setSwapMode(SWAP_MODE.STABLE)
          localStorage.setItem(SWAP_MODE_KEY, SWAP_MODE.STABLE)
        }}
      >
        <FormattedMessage id="stable_swap" defaultMessage="StableSwap" />
      </span>
      <span
        className={`py-2 w-1/2 text-center cursor-pointer ${
          swapMode === SWAP_MODE.LIQUIDITY ? 'bg-tabChosen rounded-xl' : ''
        }`}
        onClick={() => {
          setSwapMode(SWAP_MODE.LIQUIDITY)
          localStorage.setItem(SWAP_MODE_KEY, SWAP_MODE.LIQUIDITY)
        }}
      >
        <FormattedMessage id="liquidity" defaultMessage="Liquidity" />
      </span>
    </div>
  )
}

function SwapTab({
  setSwapTab,
  swapMode,
  setSwapMode,
}: {
  setSwapTab: (tab: string) => void
  swapMode: SWAP_MODE
  setSwapMode: (e?: SWAP_MODE) => void
}) {
  const TabTitle = () => {
    return <ChangeSwapMode swapMode={swapMode} setSwapMode={setSwapMode} />
  }

  return (
    <div className="lg:w-480px md:w-3/4 xs:w-full mb-5 flex items-center justify-between">
      <TabTitle />
    </div>
  )
}

function SwapPage() {
  const [metadata, setMetadata] = useState()
  const [tokenInAmount, setTokenInAmount] = useState<string>('1')

  const [swapTab, setSwapTab] = useState(localStorage.getItem(N_STABLE_SWAP_SWAPPAGE_TAB_KEY)?.toString() || 'stable')
  const [swapMode, setSwapMode] = useState<SWAP_MODE>(
    (localStorage.getItem(SWAP_MODE_KEY) as SWAP_MODE | null) || SWAP_MODE.STABLE,
  )

  const [stablePools, setStablePools] = useState<Pool[]>()

  useEffect(() => {
    Promise.all([getStablePoolFromCache('0')]).then((rawPoolInfos) => {
      // TODO: remove log raw pool info
      console.log('rawPoolInfos', rawPoolInfos)
      setStablePools(rawPoolInfos.map((PInfo) => PInfo[0]))
    })
  }, [])

  useEffect(() => {
    getSwapTokenList()
      .then((tokenIds) => {
        // @ts-ignore
        return Promise.all(tokenIds?.map((tokenId) => ftGetTokenMetadata(tokenId)))
      })
      // @ts-ignore
      .then(setMetadata)
  }, [getCurrentWallet().wallet.isSignedIn()])

  if (!stablePools || !metadata) return <Loading />

  // TODO: remove log list tokens
  console.log(metadata)

  return (
    <div className="swap">
      <section className="xs:p-2 m-auto relative flex flex-col items-center">
        <SwapTab setSwapTab={setSwapTab} swapMode={swapMode} setSwapMode={setSwapMode} />

        {swapMode === SWAP_MODE.STABLE && (
          <SwapCard
            allTokens={metadata}
            swapMode={swapMode}
            stablePools={stablePools}
            tokenInAmount={tokenInAmount}
            setTokenInAmount={setTokenInAmount}
          />
        )}

        {swapMode === SWAP_MODE.LIQUIDITY && <AddLiquidityCard />}
      </section>
    </div>
  )
}

export default SwapPage
