import React, { createContext, useContext, useReducer, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import SwapPage from './pages/SwapPage'
import { AccountPage } from '~pages/AccountPage'
import BorrowPage from '~pages/BorrowPage'
import LiquidationHistoryPage from '~pages/LiquidationHistory'
import LendingPage from '~pages/lending/LendingPage'
import LendingDetailsPage from '~pages/lending/LendingDetailsPage'
import VeNSTPage from '~pages/VeNSTPage'
import OptionTradingPage from '~pages/OptionTradingPage'
// import DepositPage from './pages/DepositPage'
// import { RecentActivityPage } from './pages/RecentActivityPage'
// import { LiquidityPage } from '~pages/pools/LiquidityPage'
// import { YourLiquidityPage } from '~pages/pools/YourLiquidityPage'
// import { AddPoolPage } from '~pages/pools/AddPoolPage'
// import { AddTokenPage } from '~pages/pools/AddTokenPage'
// import Footer from './components/layout/Footer'
// import StableSwapPage from './pages/stable/StableSwapPage'
// import { auroraAddr, getAuroraPool, getErc20Addr, useAuroraTokens } from './services/aurora/aurora'
// import { BgShapeLeftTop, BgShapeCenter, BgShapeCenterSmall } from './components/icon'
import NavigationBar from './components/layout/NavigationBar'
import Modal from 'react-modal'

import './global.css'
import './style.css'
import 'react-toastify/dist/ReactToastify.css'
import { isMobile } from '~utils/device'
import { wallet as webWallet, N_STABLE_CONTRACT_ID } from './services/near'
import { getSenderWallet, WALLET_TYPE, getCurrentWallet } from '~utils/sender-wallet'
import { getURLInfo, failToast, usnBuyAndSellToast, getErrorMessage } from '~components/layout/transactionTipPopUp'
import { senderSignedInToast } from '~components/layout/senderSignInPopUp'

import { getSenderLoginRes, saveSenderLoginRes } from '~utils/sender-wallet'

import { WalletContext, globalStateReducer, removeSenderLoginRes } from '~utils/sender-wallet'
import { checkTransaction } from '~services/swap'
import { swapToast } from '~components/layout/transactionTipPopUp'

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 100,
  },
  content: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}

Modal.setAppElement('#root')

function App() {
  const GlobalStateReducer = useReducer(globalStateReducer, {
    isSignedIn: false,
  })

  const [globalState, globalStatedispatch] = GlobalStateReducer

  const { txHash, pathname, errorType, signInErrorType } = getURLInfo()

  useEffect(() => {
    if (errorType) {
      checkTransaction(txHash).then((res) => {
        let displayErrorMessage = errorType
        const errorMessasge = getErrorMessage(res)

        if (errorMessasge) displayErrorMessage = errorMessasge

        failToast(txHash, displayErrorMessage)

        window.history.replaceState({}, '', window.location.origin + pathname)
      })

      // failing toast only once
    }
    if (signInErrorType) {
      senderSignedInToast(signInErrorType)
      removeSenderLoginRes()
      window.history.replaceState({}, '', window.location.origin + pathname)
    }
  }, [errorType, signInErrorType])
  // for usn start
  const isSignedIn = globalState.isSignedIn
  useEffect(() => {
    if (txHash && isSignedIn) {
      checkTransaction(txHash)
        .then((res: any) => {
          const slippageErrorPattern = /ERR_MIN_AMOUNT|slippage error/i

          const isSlippageError = res.receipts_outcome.some((outcome: any) => {
            return slippageErrorPattern.test(
              outcome?.outcome?.status?.Failure?.ActionError?.kind?.FunctionCallError?.ExecutionError,
            )
          })
          const transaction = res.transaction
          const methodName = transaction?.actions[0]?.['FunctionCall']?.method_name
          return {
            isUSN: methodName == 'buy' || methodName == 'sell',
            isSlippageError,
            isNearWithdraw: methodName == 'near_withdraw',
            isNearDeposit: methodName == 'near_deposit',
          }
        })
        .then(({ isUSN, isSlippageError, isNearWithdraw, isNearDeposit }) => {
          if (isUSN || isNearWithdraw || isNearDeposit) {
            isUSN && !isSlippageError && !errorType && usnBuyAndSellToast(txHash)
            ;(isNearWithdraw || isNearDeposit) && !errorType && swapToast(txHash)
            window.history.replaceState({}, '', window.location.origin + pathname)
          }
        })
    }
  }, [txHash, isSignedIn])
  // for usn end

  useEffect(() => {
    if (webWallet.isSignedIn()) {
      globalStatedispatch({ type: 'signIn' })
    }
  }, [webWallet.isSignedIn()])

  useEffect(() => {
    setTimeout(() => {
      if (window.nearFiWallet) {
        window.near = window.nearFiWallet
      }
      if (window.near) {
        getCurrentWallet()
        window.near.on('signIn', (res: any) => {
          if (getCurrentWallet().wallet_type === 'near-wallet' && webWallet.isSignedIn()) return
          saveSenderLoginRes()
          globalStatedispatch({ type: 'signIn' })
        })
        window.near.on('accountChanged', (changedAccountId: string) => {
          if (getCurrentWallet().wallet_type === 'near-wallet' && webWallet.isSignedIn()) return
          saveSenderLoginRes(changedAccountId)
          window.location.reload()
        })
        window.near.on('signOut', () => {
          if (getCurrentWallet().wallet_type === 'sender-wallet' || getCurrentWallet().wallet_type === 'nearfi-wallet') {
            removeSenderLoginRes()
            globalStatedispatch({ type: 'signOut' })
          }
        })
      }
      if (
        window.near &&
        getSenderLoginRes() &&
        (getCurrentWallet().wallet_type === 'sender-wallet' || getCurrentWallet().wallet_type === 'nearfi-wallet') &&
        !signInErrorType
      ) {
        getSenderWallet(window)
          .requestSignIn(N_STABLE_CONTRACT_ID)
          .then((res: any) => {
            !res?.error && globalStatedispatch({ type: 'signIn' }) && saveSenderLoginRes()
          })
      }
    }, 300)
  }, [window, window?.near, window?.nearFiWallet])

  return (
    <WalletContext.Provider value={{ globalState, globalStatedispatch }}>
      <Router>
        <div className="relative main-content min-h-screen pb-24 overflow-x-hidden xs:flex xs:flex-col md:flex md:flex-col">
          <NavigationBar />
          <ToastContainer
            style={{
              marginTop: isMobile() ? 'none' : '44px',
            }}
          />
          <Switch>
            <Route path="/account" component={AccountPage} />
            <Route path="/borrow/history" component={AutoHeight(LiquidationHistoryPage)} />
            <Route path="/borrow" component={AutoHeight(BorrowPage)} />
            <Route path="/lending/:id" component={AutoHeight(LendingDetailsPage)} />
            <Route path="/lending" component={AutoHeight(LendingPage)} />
            <Route path="/venst" component={AutoHeight(VeNSTPage)} />
            <Route path="/option-trading" component={AutoHeight(OptionTradingPage)} />
            <Route path="/" component={AutoHeight(SwapPage)} />
            {/* <Route path="/usn" component={AutoHeight(USNPage)} /> */}
          </Switch>
        </div>
      </Router>
    </WalletContext.Provider>
  )
}

// decorate any components with this HOC to display them as vertical-align middle
// use individual fn is needed since `h-4/5` is not a appropriate style rule for
// any components
function AutoHeight(Comp: any) {
  return (props: any) => {
    return (
      <div className="xs:flex xs:flex-col md:flex md:flex-col justify-center h-4/5 mt-12 relative">
        <Comp {...props} />
      </div>
    )
  }
}

export default App
