import getConfig from './config'
import { TokenMetadata } from './ft-contract'
import { executeMultipleTransactions, naiViewFunction, NnStableFunctionCallOptions, ONE_YOCTO_NEAR } from './near'
import { checkTokenNeedsStorageDeposit, getDepositTransactionsNai } from './token'
import { toNonDivisibleNumber } from '~utils/numbers'
import { getCurrentWallet } from '~utils/sender-wallet'
import BigNumber from 'bignumber.js'
import { storageDepositAction } from '~services/creators/storage'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })

export const { NAI_CONTRACT_ID, WRAP_NEAR_CONTRACT_ID } = getConfig()

export const naiMetadata: TokenMetadata = {
  id: NAI_CONTRACT_ID,
  name: 'NAI',
  symbol: 'NAI',
  decimals: 18,
  icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Q2e2ZpbGw6I0ZGRkZGRjt9Cgkuc3Q1OXtmaWxsOiM4NjQ3OUY7fQo8L3N0eWxlPgo8Y2lyY2xlIGNsYXNzPSJzdDU5IiBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiLz4KPHBvbHlnb24gY2xhc3M9InN0NiIgcG9pbnRzPSIxNC4wNyAxNS4wNSAxNS4zMyAxNi45NSAxNi42MSAxOC44OSAxOS44MiAyMy43NCAyMi4zNSAyMy43NCAyMi4zNSAxOC44OSAyNC43MyAxOC44OSAyNC43MyAxNi45NSAyMi4zNSAxNi45NSAyMi4zNSAxNS4wNSAyNC43MyAxNS4wNSAyNC43MyAxMy4xMSAyMi4zNSAxMy4xMSAyMi4zNSA4LjI2IDE5LjgyIDguMjYgMTkuODIgMTMuMTEgMTkuODIgMTUuMDUgMTkuODIgMTYuOTUgMTkuODIgMTguODkgMTkuODIgMTkuNzggMTkuMjIgMTguODkgMTcuOTQgMTYuOTUgMTYuNjggMTUuMDUgMTUuNCAxMy4xMSAxMi4xOCA4LjI2IDkuNjUgOC4yNiA5LjY1IDEzLjExIDcuMjcgMTMuMTEgNy4yNyAxNS4wNSA5LjY1IDE1LjA1IDkuNjUgMTYuOTUgNy4yNyAxNi45NSA3LjI3IDE4Ljg5IDkuNjUgMTguODkgOS42NSAyMy43NCAxMi4xOCAyMy43NCAxMi4xOCAxOC44OSAxMi4xOCAxNi45NSAxMi4xOCAxNS4wNSAxMi4xOCAxMy4xMSAxMi4xOCAxMi4yIDEyLjc5IDEzLjExIi8+Cjwvc3ZnPgo=',
}

export const getCurrentBorrowInfo = async (tokenId?: string) => {
  const currentBorrowInfo = await naiViewFunction({
    methodName: 'get_current_borrow_info',
    args: { account_id: getCurrentWallet().wallet.getAccountId() },
  })

  if (tokenId) {
    return currentBorrowInfo.filter((info: any) => info.token_id === tokenId)
  }
  return currentBorrowInfo
}

export const getCurrentBorrowInfoForCollateral = async ({
  tokenId,
  collateral_amount,
  borrow,
  pay_amount,
  decimals,
}: {
  tokenId: string
  collateral_amount?: string
  borrow?: string
  pay_amount?: string
  decimals: number
}) => {
  let args1 = {
    account_id: getCurrentWallet().wallet.getAccountId(),
    collateral_token_id: tokenId,
  }

  if (collateral_amount) {
    args1 = {
      ...args1,
      // @ts-ignore
      collateral_amount: new BigNumber(collateral_amount).multipliedBy(new BigNumber(10 ** decimals)).toString(),
    }
  }

  if (pay_amount) {
    args1 = {
      ...args1,
      // @ts-ignore
      pay_amount: new BigNumber(pay_amount).multipliedBy(new BigNumber(1e18)).toString(),
    }
  }

  if (borrow) {
    // @ts-ignore
    args1 = { ...args1, borrow: new BigNumber(borrow).multipliedBy(1e18).toString() }
  }

  console.log('args1', args1)

  const currentBorrowInfo = await naiViewFunction({
    methodName: 'get_current_borrow_info_for_collateral',
    args: args1,
  })

  console.log('currentBorrowInfo', currentBorrowInfo)

  return currentBorrowInfo
}

export const getMaxBorrowable = async (collateral_token_id: string, collateral_amount: string) => {
  return await naiViewFunction({
    methodName: 'compute_max_borrowable_for_account',
    args: {
      account_id: getCurrentWallet().wallet.getAccountId(),
      collateral_token_id: collateral_token_id,
      collateral_amount: collateral_amount,
    },
  })
}

export const getPriceData = async () => {
  return await naiViewFunction({
    methodName: 'get_price_data',
  })
}

interface NaiBorrowOptions {
  accountId: string
  tokenAmounts: { token: TokenMetadata; amount: string }[]
  collateral_token_id: string
  borrow_amount?: string
}

export const naiBorrow = async ({ tokenAmounts, accountId, collateral_token_id, borrow_amount }: NaiBorrowOptions) => {
  const amounts = tokenAmounts.map(({ token, amount }) => toNonDivisibleNumber(token.decimals, amount))

  const depositTransactions = await getDepositTransactionsNai({
    accountId,
    tokens: tokenAmounts.map(({ token, amount }) => token),
    amounts: tokenAmounts.map(({ token, amount }) => amount),
  })

  const _borrowAmount = Number(borrow_amount) > 0 ? new BigNumber(borrow_amount).multipliedBy(1e18).toString() : '0'

  const actions: NnStableFunctionCallOptions[] = [
    {
      methodName: 'borrow',
      args: {
        collateral_token_id: collateral_token_id,
        borrow_amount: _borrowAmount,
      },
      amount: ONE_YOCTO_NEAR,
    },
  ]

  // const neededStorage = await checkTokenNeedsStorageDeposit()
  // if (neededStorage) {
  //   actions.unshift(storageDepositAction({ amount: neededStorage }))
  // }

  return executeMultipleTransactions([
    ...depositTransactions,
    {
      receiverId: NAI_CONTRACT_ID,
      functionCalls: [...actions],
    },
  ])
}

interface NaiRepayOptions {
  accountId: string
  tokenAmounts: { token: TokenMetadata; amount: string }[]
  collateral_token_id: string
  pay_amount: string
}

export const naiRepay = async ({ tokenAmounts, accountId, collateral_token_id, pay_amount }: NaiRepayOptions) => {
  const amounts = tokenAmounts.map(({ token, amount }) => toNonDivisibleNumber(18, amount))

  const depositTransactions = await getDepositTransactionsNai({
    accountId,
    tokens: tokenAmounts.map(({ token, amount }) => token),
    amounts: tokenAmounts.map(({ token, amount }) => amount),
  })

  const _payAmount = Number(pay_amount) > 0 ? new BigNumber(pay_amount).multipliedBy(1e18).toString() : '0'

  const actions: NnStableFunctionCallOptions[] = [
    {
      methodName: 'pay_loan',
      args: {
        collateral_token_id: collateral_token_id,
        pay_amount: _payAmount,
      },
      amount: ONE_YOCTO_NEAR,
    },
  ]

  const neededStorage = await checkTokenNeedsStorageDeposit()
  if (neededStorage) {
    actions.unshift(storageDepositAction({ amount: neededStorage }))
  }

  return executeMultipleTransactions([
    ...depositTransactions,
    {
      receiverId: NAI_CONTRACT_ID,
      functionCalls: [...actions],
    },
  ])
}

interface NaiWithdrawOptions {
  collateral_token_id: string
  withdraw_amount: string
  token_decimals: number
}

export const naiWithdraw = async ({ collateral_token_id, withdraw_amount, token_decimals }: NaiWithdrawOptions) => {
  const _withdrawAmount =
    Number(withdraw_amount) > 0 ? new BigNumber(withdraw_amount).multipliedBy(10 ** token_decimals).toString() : '0'

  const actions: NnStableFunctionCallOptions[] = [
    {
      methodName: 'withdraw_collateral',
      args: {
        collateral_token_id: collateral_token_id,
        withdraw_amount: _withdrawAmount,
      },
      amount: ONE_YOCTO_NEAR,
    },
  ]

  const neededStorage = await checkTokenNeedsStorageDeposit()
  if (neededStorage) {
    actions.unshift(storageDepositAction({ amount: neededStorage }))
  }

  return executeMultipleTransactions([
    {
      receiverId: NAI_CONTRACT_ID,
      functionCalls: [...actions],
    },
  ])
}

export const getLiquidationForAccount = async () => {
  const metadata = await naiViewFunction({
    methodName: 'get_liquidations_of',
    args: {
      account_id: getCurrentWallet().wallet.getAccountId(),
    },
  })
  return metadata.sort((a: any, b: any) => b.timestamp_sec - a.timestamp_sec)
}

export const getBorrowTokenList = async (): Promise<string[]> => {
  return await naiViewFunction({
    methodName: 'get_token_list',
  })
}
