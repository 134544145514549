import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { ConnectToNearBtn, ButtonTextWrapper } from '~components/button/Button'

import { WalletContext } from '~utils/sender-wallet'

interface SubmitButtonProps {
  text?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent) => void
  info?: string | JSX.Element
  label?: string
  className?: string
  loading?: boolean
  signedInConfig?: boolean
}

function SubmitButton({ disabled, onClick, label, className, loading, signedInConfig }: SubmitButtonProps) {
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  return (
    <>
      {isSignedIn || signedInConfig ? (
        <button
          type={onClick ? 'button' : 'submit'}
          disabled={disabled}
          onClick={onClick}
          className={`flex flex-row w-full items-center justify-center px-5 py-2 mt-6 bg-primary text-white mx-auto ${
            disabled ? 'disabled:cursor-not-allowed opacity-40' : ''
          } ${loading ? 'opacity-40' : ''} ${className}`}
          style={{
            borderRadius: '5px',
          }}
        >
          {!label && (
            <h1 className="text-lg font-inter font-semibold">
              <ButtonTextWrapper loading={loading} Text={() => <FormattedMessage id="swap" defaultMessage="Swap" />} />
            </h1>
          )}
          {label && (
            <h1 className="text-lg font-inter font-semibold">
              <ButtonTextWrapper
                loading={loading}
                Text={() => <FormattedMessage id={label} defaultMessage={label} />}
              />
            </h1>
          )}
        </button>
      ) : (
        <div className="mt-4 w-full">
          <ConnectToNearBtn />
        </div>
      )}
    </>
  )
}

export default SubmitButton
