import React, { useState, useContext } from 'react'
import Modal from 'react-modal'
import { Card } from '~components/card/Card'
import ReactModal from 'react-modal'
import { Range, getTrackBackground } from 'react-range'
import TokenIcon from '~components/tokenicon/TokenIcon'
import { TokenMetadata } from '~services/ft-contract'
import { WalletContext } from '~utils/sender-wallet'
import { useWalletTokenBalance } from '~state/token'
import { isMobile } from '~utils/device'
import { FormattedMessage } from 'react-intl'
import { ModalClose } from '~components/icon'
import { ButtonTextWrapper, ConnectToNearBtn, SolidButton } from '~components/button/Button'
import { toPrecision, toReadableNumber, toRoundedReadableNumber } from '~utils/numbers'
import { toRealSymbol } from '~utils/token'
import InputAmount from '~components/forms/InputAmount'
import { nstMetadata, createVeNFT } from '~services/venst'
import { useVeMetada, useVeMetadataForAccount } from '~state/ve'
import { MdHelpOutline } from 'react-icons/md'
import ReactTooltip from 'react-tooltip'
import moment from "moment"

export function AddNSTModal(
  props: ReactModal.Props & {
    token: TokenMetadata
  },
) {
  const { token } = props

  const [tokenAmount, setTokenAmount] = useState<any>([
    {
      token: nstMetadata,
      amount: '',
    },
  ])
  const [values, setValues] = React.useState([24])
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const metadata = useVeMetada()
  const balance = useWalletTokenBalance(token.id)
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  const minWeek = Math.round(metadata?.min_days / 7)
  const maxWeek = Math.round(metadata?.max_days / 7)
  const minAmount = toReadableNumber(6, metadata?.min_locked_amount)

  const changeTokenAmount = (amount: string) => {
    const _balance = toReadableNumber(token.decimals, balance)

    tokenAmount[0].amount = amount
    setTokenAmount(tokenAmount)
    console.log('tokenAmount', tokenAmount)
    if (Number(amount) > Number(minAmount) && Number(amount) < Number(_balance)) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }

  function submit() {
    return createVeNFT({
      tokenAmount: tokenAmount,
      days: values[0],
    })
  }

  const ButtonRender = () => {
    if (!isSignedIn) {
      return <ConnectToNearBtn />
    }

    const handleClick = async () => {
      if (canSubmit) {
        setButtonLoading(true)
        await submit()
      }
    }
    return (
      <SolidButton
        disabled={!canSubmit}
        className="focus:outline-none mt-4 px-4 w-full"
        onClick={handleClick}
        loading={buttonLoading}
      >
        <div className="flex items-center justify-center w-full m-auto">
          <div>
            <ButtonTextWrapper
              loading={buttonLoading}
              Text={() => <FormattedMessage id="confirm" defaultMessage="Confirm" />}
            />
          </div>
        </div>
      </SolidButton>
    )
  }

  return (
    <Modal {...props}>
      <Card
        style={{ width: isMobile() ? '95vw' : '480px' }}
        width="w-10/12"
        padding="px-6 py-8"
        bgcolor="bg-secondary"
        className="text-primaryText outline-none "
      >
        <div className="flex items-start justify-between">
          <div>
            <div className="text-base font-medium pb-2">
              <FormattedMessage id="confirm_nst_stake" defaultMessage="Confirm Stake NST" />
            </div>
          </div>
          <div className="ml-2 cursor-pointer p-1" onClick={props.onRequestClose}>
            <ModalClose />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-end items-center text-xs text-right mb-1 text-gray-400">
            <FormattedMessage id="balance" defaultMessage="Balance" />
            :&nbsp;
            <span title={toReadableNumber(token.decimals, balance)}>
              {toPrecision(toReadableNumber(token.decimals, balance), 2, true)}
            </span>
          </div>
          <div className="flex items-center ">
            <div className="flex items-center mr-4 w-1/3">
              <TokenIcon icon={token.icon} className="h-6 w-6 mr-2" />
              <div className="text-base" title={token.id}>
                {toRealSymbol(token.symbol)}
              </div>
            </div>
            <InputAmount
              className="w-full border border-transparent rounded"
              max={toReadableNumber(token.decimals, balance)}
              onChangeAmount={changeTokenAmount}
              value={token.amount}
              name={token.id}
              data-decimals={token.decimals}
            />
          </div>
          <div className="my-5">
            <div className="flex justify-between items-center text-sm text-gray-500">
              <span>Lock for: {`${values[0]} ${values[0] > 1 ? 'weeks' : 'week'}`}</span>
              {minAmount && <span>Min Amount: {minAmount} NST</span>}
            </div>
            <Range
              min={minWeek}
              max={maxWeek}
              step={1}
              onChange={(values) => setValues(values)}
              values={values}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '30px',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '8px',
                      width: '100%',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      background: getTrackBackground({
                        values,
                        colors: ['rgba(120, 59, 255, 0.7)', '#fff'],
                        min: minWeek,
                        max: maxWeek,
                      }),
                      alignSelf: 'center',
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: '10px',
                    backgroundColor: '#783BFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              )}
            />
            <div className="flex justify-between items-center -mt-1 text-gray-400 text-xs">
              <span>{minWeek}</span>
              <span>{maxWeek}</span>
            </div>
          </div>
          <ButtonRender />
        </div>
      </Card>
    </Modal>
  )
}

function VeNSTTableRow({
  idVeNST,
  infoVeNST,
}: {
  infoVeNST: {
    creator: string
    create_time_sec: number
    locked_token_amount: string
    locked_till: number
    voting_power: string
  }
  idVeNST: number[]
}) {
  const _lockedAmount = toRoundedReadableNumber({
    decimals: 6,
    number: infoVeNST.locked_token_amount,
    precision: 3,
  })

  return (
    <>
      <td>
        <span className="font-semibold text-primary">
          {`#${idVeNST}`}
        </span>
      </td>
      <td>
        <span
          className="relative top-0.5 inline-block"
          data-type="info"
          data-place="top"
          data-multiline={true}
          data-class="reactTip"
          data-html={true}
          data-tip={`Locked ${_lockedAmount} NST`}
          data-for={`amount-${infoVeNST.locked_till}`}
        >
          {_lockedAmount}
        </span>
        <ReactTooltip
          className="w-360px"
          id={`amount-${infoVeNST.locked_till}`}
          backgroundColor="#1D2932"
          border
          borderColor="#7e8a93"
          effect="solid"
        />
      </td>
      <td>
        <span
          className="relative top-0.5 inline-block"
          data-type="info"
          data-place="top"
          data-multiline={true}
          data-class="reactTip"
          data-html={true}
          data-tip={`Create from ${moment.unix(infoVeNST.create_time_sec).fromNow()}`}
          data-for={`time-${infoVeNST.locked_till}`}
        >
          {moment.unix(infoVeNST.locked_till).format('MMM Do YYYY')}
        </span>
        <ReactTooltip
          className="w-360px"
          id={`time-${infoVeNST.locked_till}`}
          backgroundColor="#1D2932"
          border
          borderColor="#7e8a93"
          effect="solid"
        />
      </td>
      <td className="text-right">
        {toRoundedReadableNumber({
          decimals: 6,
          number: infoVeNST.voting_power,
          precision: 4,
        })}
      </td>
    </>
  )
}

function VeNSTPage() {
  const [showVeNST, setShowVeNST] = useState(false)

  const metadataForAccount = useVeMetadataForAccount()

  const idVeNST = metadataForAccount[0]
  const infoVeNST = metadataForAccount[1]

  const deposited = toRoundedReadableNumber({
    decimals: 6,
    number: metadataForAccount[2],
    precision: 4,
  })
  const staked = toRoundedReadableNumber({
    decimals: 6,
    number: metadataForAccount[3],
    precision: 4,
  })
  const votingPower = toRoundedReadableNumber({
    decimals: 6,
    number: metadataForAccount[4],
    precision: 4,
  })

  // TODO: remove log metadataForAccount
  console.log('metadataForAccount', metadataForAccount)

  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn
  return (
    <section className="xs:p-2 m-auto relative flex flex-col items-center">
      <div className="lg:w-640px md:w-3/4 xs:w-full overflow-y-visible bg-secondary shadow-4xl rounded-2xl p-7 mb-5 bg-light xs:rounded-lg md:rounded-lg overflow-x-visible">
        <h2 className="text-primaryText text-xl font-medium text-center">Stake NST to Boost Yield</h2>
        <div className="mt-5">
          <div className="flex justify-between items-center py-2 text-lg border-b">
            <span className="block text-gray-400">Staked:&nbsp;</span>
            <span className="font-medium">{staked}&nbsp;NST</span>
          </div>
          <div className="flex justify-between items-center py-2 text-lg border-b">
            <span className="block text-gray-400">
              Voting Power:
              <span
                className="relative top-0.5 inline-block ml-1"
                data-type="info"
                data-place="right"
                data-multiline={true}
                data-class="reactTip"
                data-html={true}
                data-tip="Voting Power tooltip content"
                data-for="votingPower"
              >
                <MdHelpOutline size={18} />
              </span>
              <ReactTooltip
                className="w-360px"
                id="votingPower"
                backgroundColor="#1D2932"
                border
                borderColor="#7e8a93"
                effect="solid"
              />
            </span>
            <span className="font-medium">{votingPower}</span>
          </div>
          {Number(deposited) > 0 && (
            <div className="flex justify-between items-center py-2 text-lg border-b">
              <span className="block text-gray-400">
                Deposited:
                <span
                  className="relative top-0.5 inline-block ml-1"
                  data-type="info"
                  data-place="right"
                  data-multiline={true}
                  data-class="reactTip"
                  data-html={true}
                  data-tip="Deposited tooltip content"
                  data-for="deposited"
                >
                  <MdHelpOutline size={18} />
                </span>
                <ReactTooltip
                  className="w-360px"
                  id="deposited"
                  backgroundColor="#1D2932"
                  border
                  borderColor="#7e8a93"
                  effect="solid"
                />
              </span>
              <span className="font-medium">{deposited}&nbsp;NST</span>
            </div>
          )}
        </div>
        <div className="mt-5">
          {isSignedIn ? (
            <SolidButton
              padding="px-3"
              className="h-10 w-full md:text-sm xs:text-sm"
              onClick={() => {
                setShowVeNST(true)
              }}
            >
              <FormattedMessage id="stake" defaultMessage="Stake" />
            </SolidButton>
          ) : (
            <ConnectToNearBtn />
          )}
        </div>

        <AddNSTModal
          isOpen={showVeNST}
          token={nstMetadata}
          onRequestClose={() => setShowVeNST(false)}
          overlayClassName=""
          style={{
            overlay: {
              backdropFilter: 'blur(4px)',
              WebkitBackdropFilter: 'blur(4px)',
            },
            content: {
              outline: 'none',
              position: 'fixed',
              bottom: '50%',
            },
          }}
        />
      </div>
      {infoVeNST?.length > 0 && (
        <div className="lg:w-640px md:w-3/4 xs:w-full overflow-y-visible bg-secondary shadow-4xl rounded-2xl p-7 mb-5 bg-light xs:rounded-lg md:rounded-lg overflow-x-scroll">
          <h2 className="mb-4 pb-3 text-primaryText text-xl text-center font-medium">List veNST</h2>
          <table className="nstable-table w-full mb-3 text-primaryText">
            <thead>
              <tr>
                <th scope="col">veNST NFT</th>
                <th scope="col">Locked Amount</th>
                <th scope="col">Locked Till</th>
                <th className="text-right" scope="col">Voting Power</th>
              </tr>
            </thead>
            <tbody>
              {infoVeNST?.map(
                (
                  info: {
                    creator: string
                    create_time_sec: number
                    locked_token_amount: string
                    locked_till: number
                    voting_power: string
                  },
                  i: React.Key,
                ) => (
                  <tr key={i}>
                    <VeNSTTableRow idVeNST={idVeNST[i]} infoVeNST={info} />
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      )}
    </section>
  )
}

export default VeNSTPage
