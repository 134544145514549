import React from 'react'
import { TiArrowSortedUp } from 'react-icons/ti'
import { TokenMetadata } from '~services/ft-contract'
import { TokenBalancesView } from '~services/token'
import { toReadableNumber } from '~utils/numbers'
import Token from '../../components/tokens/Token'
import { FormattedMessage } from 'react-intl'

interface TokenListProps {
  tokens: TokenMetadata[]
  sortBy: string
  currentSort: string
  onSortChange?: (sortBy: string) => void
  onClick?: (token: TokenMetadata) => void
  balances?: TokenBalancesView
  tokenPriceList: Record<string, any>
  forCross?: boolean
}

export default function Table({
  tokens,
  sortBy,
  currentSort,
  onSortChange,
  onClick,
  balances,
  tokenPriceList,
  forCross,
}: TokenListProps) {
  return (
    tokens.length > 0 && (
      <div className="text-left w-full text-sm text-gray-400 mt-5 table-auto">
        <div className="font-normal border-b border-gray-500 border-opacity-30 flex">
          <div className="font-normal w-1/2 pb-2 pl-8">
            <span className="">
              <FormattedMessage id="asset_label" defaultMessage="Asset" />
            </span>
          </div>
          <div className={`font-normal pb-2 pr-9 w-1/2 `}>
            <span
              className="cursor-pointer flex justify-end items-center whitespace-nowrap"
              onClick={() => onSortChange('near')}
            >
              <span className="ml-1">
                <FormattedMessage id="balance" />
              </span>
              <TiArrowSortedUp
                className={`inline-block cursor-pointer ${
                  sortBy === 'near' && currentSort === 'down' ? 'transform rotate-180' : ''
                }`}
              />
            </span>
          </div>
        </div>
        <div>
          {tokens
            .filter((token) => !!token)
            .map((token, index) => (
              <Token
                index={index}
                key={token.id}
                onClick={onClick}
                token={token}
                price={tokenPriceList[token.id]?.price}
                sortBy={sortBy}
                totalAmount={balances ? toReadableNumber(token.decimals, balances[token.id]) : ''}
              />
            ))}
        </div>
      </div>
    )
  )
}
