import React, { useContext, useEffect, useState } from 'react'
import { Card } from '~components/card/Card'
import Modal from 'react-modal'
import axios from 'axios'
import InputEmail from '~components/forms/InputEmail'
import { FormattedMessage } from 'react-intl'
import { useBorrowTokenList, useLiquidationsAccount } from '~state/nai'
import { toReadableNumber, toRoundedReadableNumber } from '~utils/numbers'
import { BigNumber } from 'bignumber.js'
import { ftGetTokensMetadata } from '~services/ft-contract'
import { getAccountName, getCurrentWallet, WalletContext } from '~utils/sender-wallet'
import getConfig from '~services/config'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import SubmitButton from '~components/forms/SubmitButton'
import { isMobile } from '~utils/device'
import { ModalClose } from '~components/icon'
import { ButtonTextWrapper, SolidButton } from '~components/button/Button'

function LiquidationTableRow({
  liquidation,
  tokenList,
  index,
}: {
  liquidation: {
    maker_id: string
    token_id: string
    collateral_amount_before: string
    collateral_amount_after: string
    borrowed_before: string
    borrowed_after: string
    timestamp_sec: number
    price: {
      multiplier: string
      decimals: number
    }
  }
  tokenList: any
  index: number
}) {
  const liquidatedCollateral = new BigNumber(liquidation.collateral_amount_before)
    .minus(new BigNumber(liquidation.collateral_amount_after))
    .toString()

  const _repay = new BigNumber(liquidation.borrowed_before).minus(new BigNumber(liquidation.borrowed_after)).toString()

  return (
    <>
      <td>{index + 1}</td>
      <td>
        <span
          className="relative top-0.5 inline-block ml-1"
          data-type="info"
          data-place="top"
          data-multiline={true}
          data-class="reactTip"
          data-html={true}
          data-tip={liquidation.token_id}
          data-for={`token-${index}`}
        >
          {tokenList[`${liquidation.token_id}`]?.symbol}
        </span>
        <ReactTooltip
          className="w-360px"
          id={`token-${index}`}
          backgroundColor="#1D2932"
          border
          borderColor="#7e8a93"
          effect="solid"
        />
      </td>
      <td>
        {toRoundedReadableNumber({
          decimals: tokenList[`${liquidation.token_id}`]?.decimals,
          number: liquidatedCollateral,
          precision: 9,
        })}
        &nbsp;
        {tokenList[`${liquidation.token_id}`]?.symbol}
      </td>
      <td>{toReadableNumber(18, _repay)}</td>
      <td>
        {toRoundedReadableNumber({
          decimals: liquidation.price.decimals,
          number: liquidation.price.multiplier,
          precision: 4,
        })}
      </td>
      <td>
        <a
          href={`${getConfig().explorerUrl}/accounts/${liquidation.maker_id}`}
          title={liquidation.maker_id}
          target="_blank"
          rel="noopener"
        >
          <span
            className="relative top-0.5 inline-block ml-1"
            data-type="info"
            data-place="top"
            data-multiline={true}
            data-class="reactTip"
            data-html={true}
            data-tip={liquidation.maker_id}
            data-for={`liquidator-${index}`}
          >
            <span className="text-primary">{getAccountName(liquidation.maker_id)}</span>
          </span>
          <ReactTooltip
            className="w-360px"
            id={`liquidator-${index}`}
            backgroundColor="#1D2932"
            border
            borderColor="#7e8a93"
            effect="solid"
          />
        </a>
      </td>
      <td className="text-right">
        <span
          className="relative top-0.5 inline-block ml-1"
          data-type="info"
          data-place="top"
          data-multiline={true}
          data-class="reactTip"
          data-html={true}
          data-tip={moment.unix(liquidation.timestamp_sec).format('MMM Do YYYY, HH:mm:ss')}
          data-for={`time-${index}`}
        >
          {moment.unix(liquidation.timestamp_sec).fromNow()}
        </span>
        <ReactTooltip
          className="w-360px"
          id={`time-${index}`}
          backgroundColor="#1D2932"
          border
          borderColor="#7e8a93"
          effect="solid"
        />
      </td>
    </>
  )
}

export function SubscribeModal(props: ReactModal.Props) {
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [subscribeSuccess, setSubscribeSuccess] = useState<boolean>(false)
  const [unSubscribeSuccess, setUnSubscribeSuccess] = useState<boolean>(false)

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const handleChangeAmount = (value: string) => {
    setEmail(value)
    if (isValidEmail(value)) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }

  const subscribe = async () => {
    try {
      setButtonLoading(true)
      if (isValidEmail(email)) {
        const response = await axios.post(`${getConfig().nearfiApiUrl}/liquidate-notifications/subscribe`, {
          accountId: getCurrentWallet().wallet.getAccountId(),
          email,
          application: 'nai',
        })
        if (response.status === 200) {
          setButtonLoading(false)
          setSubscribeSuccess(true)
          // TODO: remove log
          console.log('Good luck and god bless you.')
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error)
        setButtonLoading(false)
        setError(error.response.data.errors)
        setCanSubmit(false)
      }
    } finally {
      setButtonLoading(false)
      setCanSubmit(false)
    }
  }

  const unsubscribe = async () => {
    try {
      setButtonLoading(true)
      if (isValidEmail(email)) {
        const response = await axios.post(`${getConfig().nearfiApiUrl}/liquidate-notifications/unsubscribe`, {
          accountId: getCurrentWallet().wallet.getAccountId(),
          email,
          application: 'nai',
        })
        if (response.status === 200) {
          setButtonLoading(false)
          setUnSubscribeSuccess(true)
          // TODO: remove log
          console.log('Bye bye')
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error)
        setButtonLoading(false)
        setError(error.response.data.errors)
        setCanSubmit(false)
      }
    } finally {
      setButtonLoading(false)
      setCanSubmit(false)
    }
  }

  return (
    <Modal {...props}>
      <Card
        style={{ width: isMobile() ? '95vw' : '480px' }}
        width="w-10/12"
        padding="px-6 py-8"
        bgcolor="bg-secondary"
        className="text-primaryText outline-none "
      >
        <div className="flex items-start justify-between">
          <div>
            <div className="text-base pb-2">
              <FormattedMessage id="subscribe" />
            </div>
          </div>
          <div className="ml-2 cursor-pointer p-1" onClick={props.onRequestClose}>
            <ModalClose />
          </div>
        </div>
        {subscribeSuccess ? (
          <div className="flex flex-col items-center">
            <div className="mx-auto mt-8 mb-10">
              <svg className="subscribe-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            </div>
            <h2 className="text-center text-lg font-semibold mb-10">
              <FormattedMessage id="subscribe_successfully" />
            </h2>
          </div>
        ) : (
          <div className=" overflow-hidden ">
            <InputEmail
              value={email}
              onChangeEmail={(value) => {
                handleChangeAmount(value)
              }}
              className="border border-transparent rounded my-4"
            />
            {error && <p className="text-xs text-error pb-4">{error}</p>}
            <SolidButton
              disabled={!canSubmit}
              className={`focus:outline-none px-4 mb-4 w-full`}
              onClick={subscribe}
              loading={buttonLoading}
            >
              <ButtonTextWrapper loading={buttonLoading} Text={() => <FormattedMessage id="subscribe" />} />
            </SolidButton>
            <SolidButton
              disabled={!canSubmit}
              className={`focus:outline-none px-4 w-full`}
              onClick={unsubscribe}
              loading={buttonLoading}
            >
              <ButtonTextWrapper loading={buttonLoading} Text={() => <FormattedMessage id="unsubscribe" />} />
            </SolidButton>
            {unSubscribeSuccess && (
              <p className="text-success">
                <FormattedMessage id="unsubscribe_successfully" />
              </p>
            )}
          </div>
        )}
      </Card>
    </Modal>
  )
}

function LiquidationHistoryPage() {
  const [metadata, setMetadata] = useState<any>()
  const { liquidations, totalTransaction } = useLiquidationsAccount()
  const [showSubscribe, setShowSubscribe] = useState<boolean>(false)
  const tokenList = useBorrowTokenList()

  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  useEffect(() => {
    if (tokenList) {
      ftGetTokensMetadata(tokenList).then(setMetadata)
    }
  }, [tokenList])

  return (
    <div className="flex flex-col mx-auto items-center -mt-5 xs:px-4 md:px-4 max-w-6xl lg:w-4/5">
      <Card
        width="w-full"
        padding="px-4 py-12"
        bgcolor="bg-secondary"
        className="text-primaryText outline-none shadow-4xl"
      >
        <div className="mb-3 text-md lg:text-xl font-semibold gray-400">
          <FormattedMessage id="liquidation_history" defaultMessage="Liquidation History" />
        </div>
        {totalTransaction > 0 && (
          <div className="mb-5 text-md gray-400">{`A total of ${totalTransaction} liquidation ${
            totalTransaction > 1 ? 'transactions' : 'transaction'
          } found`}</div>
        )}
        <div className="block w-full overflow-x-auto overflow-scroll">
          <table className="nstable-table w-full mb-3 text-primaryText">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Collateral Token</th>
                <th scope="col">Liquidated Collateral</th>
                <th scope="col">Repay(NAI)</th>
                <th scope="col">Liquidation Price</th>
                <th scope="col">Liquidator</th>
                <th className="text-right" scope="col">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
              {liquidations.length > 0 ? (
                <>
                  {/*TODO: pagination*/}
                  {liquidations?.map((liquidation, i) => (
                    <tr key={i}>
                      <LiquidationTableRow liquidation={liquidation} tokenList={metadata} index={i} />
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td className="px-2 text-center" colSpan={10} style={{ backgroundColor: '#e5effb' }}>
                    <span className="py-3 block">No liquidation history</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-10 mb-5 pt-8 text-center flex flex-col items-center border-t">
          <p>{<FormattedMessage id="subscribe_description" />}</p>
          <div className="min-w-[50%]">
            <SubmitButton
              onClick={() => {
                setShowSubscribe(true)
              }}
              label="subscribe"
            />
          </div>
        </div>
      </Card>
      <SubscribeModal
        isOpen={showSubscribe}
        onRequestClose={() => setShowSubscribe(false)}
        style={{
          overlay: {
            backdropFilter: 'blur(4px)',
            WebkitBackdropFilter: 'blur(4px)',
          },
          content: {
            outline: 'none',
            position: 'fixed',
            bottom: '50%',
          },
        }}
      />
    </div>
  )
}

export default LiquidationHistoryPage
