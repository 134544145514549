import React, { useContext, useEffect, useRef, useState } from 'react'
import { matchPath } from 'react-router'
import { Near, IconAirDropGreenTip } from '~components/icon'
import { Link, useLocation } from 'react-router-dom'
// import { near, wallet } from '~services/near'
import { useHistory } from 'react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import { HiOutlineExternalLink } from 'react-icons/hi'

import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { toPrecision } from '~utils/numbers'
import { moreLinks } from '~utils/menu'
import { useDepositableBalance } from '~state/token'
import { nearMetadata } from '~services/wrap-near'
import getConfig from '~services/config'

import { WalletContext } from '~utils/sender-wallet'

const config = getConfig()
import { getCurrentWallet, getAccountName } from '~utils/sender-wallet'
import { N_STABLE_SWAP_SWAPPAGE_TAB_KEY } from '~pages/SwapPage'
import { HiMenu } from "react-icons/hi"

export function MobileAnchor({
  to,
  pattern,
  name,
  className,
  onClick,
}: {
  to: string
  pattern: string
  name: string
  className?: string
  onClick: () => void
}) {
  const location = useLocation()
  const isSelected = matchPath(location.pathname, {
    path: pattern,
    exact: true,
    strict: false,
  })

  return (
    <div>
      <Link onClick={onClick} to={to}>
        <div
          className={`p-4 text-lg link ${className} ${
            isSelected ? 'text-white bg-navHighLightBg' : 'text-primaryText'
          }`}
        >
          <FormattedMessage id={name} defaultMessage={name} />
        </div>
      </Link>
    </div>
  )
}

export function Logout() {
  const { wallet } = getCurrentWallet()

  return (
    wallet.isSignedIn() && (
      <div
        className={'whitespace-nowrap flex text-lg text-left p-4 text-primaryText bg-cardBg'}
        onClick={() => {
          wallet.signOut()
          window.location.assign('/')
        }}
      >
        <RiLogoutCircleRLine className="text-2xl text-primaryText mr-3" />
        <FormattedMessage id="sign_out" defaultMessage="Sign out" />
      </div>
    )
  )
}

export function AccountModel(props: any) {
  const history = useHistory()
  const accountWrapRef = useRef(null)

  const { wallet } = getCurrentWallet()

  const { hasBalanceOnRefAccount } = props

  const accountList = [
    {
      textId: 'view_account',
      selected: location.pathname == '/account',
      click: () => {
        if (location.pathname == '/account') {
          localStorage.setItem(N_STABLE_SWAP_SWAPPAGE_TAB_KEY, 'normal')
          window.location.reload()
        } else {
          history.push('/account')
        }
      },
    },
    {
      textId: 'go_to_near_wallet',
      subIcon: <HiOutlineExternalLink />,
      click: () => {
        window.open(config.walletUrl, '_blank')
      },
    },
    {
      textId: 'sign_out',
      click: () => {
        wallet.signOut()
        window.location.assign('/')
      },
    },
  ]
  const handleClick = (e: any) => {
    if (!accountWrapRef.current.contains(e.target)) {
      props.closeAccount()
    }
  }
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.addEventListener('click', handleClick, false)
    return () => {
      document.body.style.overflow = 'auto'
      document.removeEventListener('click', handleClick, false)
    }
  }, [])
  return (
    <div
      className="fixed left-0 bottom-0 w-screen bg-white bg-opacity-20"
      style={{
        backdropFilter: 'blur(4px)',
        WebkitBackdropFilter: 'blur(4px)',
        top: '4.2rem',
      }}
    >
      <div className="w-11/12 bg-white" ref={accountWrapRef}>
        {accountList.map((item) => {
          return (
            <>
              <div
                onClick={() => {
                  item.click()
                  props.closeAccount()
                }}
                key={item.textId}
                className={`flex items-center text-base cursor-pointer font-semibold py-4 pl-20 hover:text-white hover:bg-navHighLightBg ${
                  item.selected ? 'text-white bg-navHighLightBg' : 'text-primaryText'
                }`}
              >
                <label className="cursor-pointer">
                  <FormattedMessage id={item.textId}></FormattedMessage>
                </label>
                {item.subIcon ? <label className="text-lg ml-2">{item.subIcon}</label> : null}
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}
export function MobileNavBar(props: any) {
  const [show, setShow] = useState(false)
  const iconRef = useRef<HTMLSpanElement | null>(null)
  const popupRef = useRef<HTMLDivElement | null>(null)
  const [openMenu, setOpenMenu] = useState('')
  const [closeMenu, setCloseMenu] = useState(false)
  const history = useHistory()
  const [mobileWrapNear, setMobileWrapNear] = useState(false)

  const { setShowWalletSelector, hasBalanceOnRefAccount } = props
  const { globalState } = useContext(WalletContext)
  const isSignedIn = globalState.isSignedIn

  const [showTip, setShowTip] = useState<boolean>(false)
  const [showUSN, setShowUSN] = useState(false)

  useEffect(() => {
    setShowTip(hasBalanceOnRefAccount)
  }, [hasBalanceOnRefAccount])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTip(false)
    }, 4000)

    return () => clearTimeout(timer)
  }, [showTip])

  const { wallet } = getCurrentWallet()

  const nearBalance = useDepositableBalance(nearMetadata.id, nearMetadata.decimals)
  const [accountVisible, setAccountVisible] = useState(false)

  useEffect(() => {
    document.addEventListener('click', handleClick, false)

    return () => {
      document.removeEventListener('click', () => {}, false)
    }
  }, [])
  useEffect(() => {
    if (mobileWrapNear) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [mobileWrapNear])

  const handleClick = (e: any) => {
    if (iconRef.current.contains(e.target) || popupRef.current.contains(e.target)) {
      return
    }
    setShow(false)
  }

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [show])

  function close() {
    setShow(false)
  }
  function handleMenuClick(url: string, label: string, isExternal: boolean) {
    if (url) {
      isExternal ? window.open(url) : history.push(url)
      close()
    } else if (openMenu === label) {
      setCloseMenu(!closeMenu)
    } else {
      setOpenMenu(label)
      setCloseMenu(true)
    }
  }
  return (
    <>
      <div className="nav-wrap bg-secondary fixed bottom-0 left-0 right-0 lg:hidden md:show" style={{
        zIndex: 99,
      }}>
        <div className="flex py-3 px-2 justify-between border-t">
          {isSignedIn && (
            <div className="flex pr-4 py-1 justify-between items-center">
              <span className="text-sm">{toPrecision(nearBalance, 3, true)} &nbsp;</span> <Near color="black" />
            </div>
          )}
          <div
            className={`flex px-1 mr-px items-center justify-center border border-primary bg-primary text-white pl-3 pr-3`}
          >
            <div className="pr-1">
              <Near color="white" />
            </div>
            <div className="overflow-ellipsis overflow-hidden text-xs whitespace-nowrap account-name relative">
              {isSignedIn ? (
                <div
                  className="flex items-center"
                  onClick={() => {
                    setAccountVisible(!accountVisible)
                    setShowTip(false)
                  }}
                >
                  <div>{getAccountName(wallet.getAccountId())}</div>

                  {accountVisible ? (
                    <FiChevronUp className="text-base ml-1" />
                  ) : (
                    <FiChevronDown className="text-base ml-1" />
                  )}
                </div>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setShowWalletSelector(true)
                  }}
                  type="button"
                >
                  <span className="ml-2 text-xs">
                    <FormattedMessage id="connect_to_near" defaultMessage="Connect to NEAR" />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="nav-wrap bg-secondary left-0 right-0 lg:hidden md:show"
        style={{
          zIndex: show ? 200 : 51,
        }}
      >
        <div className="flex items-center text-2xl justify-between py-2">
          <div className="flex w-full justify-between items-center">
            <span
              className="text-primaryText hover:text-primary cursor-pointer"
              onClick={() => setShow(true)}
              ref={iconRef}
            >
              <HiMenu size={24} fill="#121212" />
            </span>
          </div>
        </div>
        <div
          className={`fixed top-0 bottom-0 left-0 z-20 w-full bg-black bg-opacity-30 backdrop-blur-lg filter-blur backdrop-filter overflow-auto ${
            show ? 'block' : 'hidden'
          }`}
          style={{
            zIndex: '80',
          }}
        >
          <div ref={popupRef} className="block h-full overflow-y-scroll w-4/6 float-right bg-cardBg shadow-4xl z-30">
            <div className="text-primaryText divide-y divide-primaryText border-t border-b border-primaryText divide-opacity-30 border-opacity-30">
              {moreLinks.map(
                ({ id, label, subRoute, pattern, url, isExternal, children, newFunction, showIcon, iconElement }) => {
                  let location = useLocation()
                  let isSelected = subRoute
                    ? subRoute.includes(location.pathname)
                    : matchPath(location.pathname, {
                        path: pattern,
                        exact: true,
                        strict: false,
                      })
                  return (
                    <div key={id}>
                      <div
                        className={`flex p-4 items-center text-lg justify-between ${
                          isSelected ? (!children ? 'bg-navHighLightBg text-primary' : 'text-primary') : 'text-primaryText'
                        }`}
                        onClick={() => handleMenuClick(url, label, isExternal)}
                      >
                        {showIcon ? (
                          <span className={`py-2 ${isSelected ? 'opacity-100' : 'opacity-50'}`}>{iconElement}</span>
                        ) : (
                          <div className={`link relative`}>
                            <FormattedMessage id={id} defaultMessage={label} />
                            {newFunction ? (
                              <span className="absolute top-1 -right-2">
                                <IconAirDropGreenTip />
                              </span>
                            ) : null}
                          </div>
                        )}
                        {children && (
                          <span>
                            <FiChevronUp
                              className={`${openMenu === label && closeMenu ? 'inline-block' : 'hidden'} text-xl`}
                            />
                            <FiChevronDown
                              className={`${openMenu !== label || !closeMenu ? 'inline-block' : 'hidden'} text-xl`}
                            />
                          </span>
                        )}
                      </div>
                      {children && (
                        <div className={`${openMenu === label && closeMenu ? 'block' : 'hidden'}`}>
                          {children?.map((link) => {
                            let isSubMenuSelected: any = matchPath(location.pathname, {
                              path: link.pattern,
                              exact: true,
                              strict: false,
                            })
                            if (
                              location.pathname.startsWith('/pool/') ||
                              location.pathname.startsWith('/more_pools/')
                            ) {
                              if (link.id === 'view_pools') {
                                isSubMenuSelected = true
                              }
                            }
                            return (
                              <div
                                key={link.id}
                                className={`whitespace-nowrap text-left items-center p-3 pl-10 flex justify-start ${
                                  !link.isExternal && isSubMenuSelected
                                    ? 'text-primary bg-navHighLightBg'
                                    : 'text-primaryText'
                                }`}
                                onClick={() => {
                                  link.url && link.isExternal ? window.open(link.url) : history.push(link.url)
                                  close()
                                }}
                              >
                                {link.logo && (
                                  <span className="text-xl text-left w-8 flex justify-center mr-2">{link.logo}</span>
                                )}
                                <FormattedMessage id={link.id} defaultMessage={link.label} />
                                {link.tip && (
                                  <span className="ml-2 bg-gradientFrom px-2 flex justify-center items-center text-white text-xs rounded-full">
                                    {link.tip}
                                  </span>
                                )}

                                {link.url && link.isExternal && (
                                  <HiOutlineExternalLink className="float-right ml-2 text-xl opacity-60" />
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )
                },
              )}
            </div>
          </div>
        </div>
        {accountVisible ? (
          <AccountModel
            hasBalanceOnRefAccount={hasBalanceOnRefAccount}
            closeAccount={() => {
              setAccountVisible(false)
            }}
          />
        ) : null}
      </div>
    </>
  )
}
